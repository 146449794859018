import React, { Component, Fragment } from 'react'
import img1 from '../../securedir/wp-content/themes/towellers/images/story/story-2.png'
import img2 from '../../securedir/wp-content/themes/towellers/images/story/story-1.png'
import img3 from '../../securedir/wp-content/themes/towellers/images/story/story-3.png'
import img4 from '../../securedir/wp-content/themes/towellers/images/map.png'


class Story extends Component {
  render() {
    return (
      <Fragment>
        <section>
        <div className="container-fluid">
            <div className="row">
            <div className="col-12">
                <div className="text-center">
                <div className="py-5 bg-white">
                    <h1 className="display-2 font-weight-bold">Welcome To Hasham Towel</h1>
                    <div className="row">
                    <div className="col-12 col-lg-8 mx-auto">
                        <p>Since the establishment of <strong>Hasham Towels</strong> in 1988, the company has become well known for its continuous efforts to develop latest designs in towels and bathrobes. For 25 years, the company has achieved a significant level of etxport sales and has become well known globally for supplying quality products. The company has grown considerably since its early days and has established its mark in bath accessories. An inherent desire for technical excellence leads Hasham to the achievement of ISO 9002 Certification. We successfully participate in a number of international trade fairs throughout the year including the hemitextil, Frankfurt, displaying the best towels and bathrobes of all types and sizes. OEKOTEX Certificate ensures that all our products are free from harmful substances and can be used confidently.
                        </p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>
        <div id="story" className="position-relative container">
        <svg className="thread-anim thread-story d-none d-lg-block" fill="none"
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1581 3006">
            <path
            d="M230.5 1C196.3 107.2 187.2 336.8 424 406c296 86.5-424 7-396.5 353.5 22 277.2 314.2 403.8 457.5 432.5-160.8 64-477.5 252.3-457.5 493.5C52.5 1987 550 1726.7 435 2034c-97.5 260.5-287.5-89-433.5 432.5 99 2.2 314.7 66.4 385.5 306 88.5 299.5 360 242 684.5 145.5 259.6-77.2 504.5 25.8 594.5 87"
            stroke="#bdbc50" stroke-width="2" stroke-miterlimit="10"
            stroke-linejoin="round" className="thread-story-1"></path>
        </svg>

        <div className="row">
            <div className="col-12">
        <section>
            <div className="container py-4 z-1">
            <div className="row align-items-center justify-content-center">
                <div className="col-12 col-lg-4 order-0 order-lg-1">
                <img src={img2} alt="Story 1"
                    className="img-fluid mb-4 md-lg-0 w-100" />
                </div>
                <div className="col-12 col-lg-4 order-1 order-lg-0">
                <h2 className="display-3">
                    <span> Towel <br className="d-none d-lg-block" /></span>
                </h2>
                <p>All types and sizes of towels are manufactured in eye catchy designs. Dobby, Embroidered, Jacquard, Kitchen, Open end, Ring spun, Carded, Combed, Egyptian, Stripe Beach, Jacquard Beach,Velour Beach, Kitchen, Embroidered, Printed, Kids Towels. Printedand SingleColor all are included.</p>
                </div>
            </div>
            </div>
        </section>

        <section className="position-relative">
            <div className="container py-4">
            <div className="row align-items-center justify-content-center py-5">
                <div className="col-12 col-lg-4">
                <img src={img1}
                    className="img-fluid mb-4 md-lg-0 w-100" />
                </div>
                <div className="col-12 col-lg-4">
                <h2 className="display-3">
                    Bathrobes
                </h2>
                <ul> 
                    <li> Velour Bathrobes</li>
                    <li> Kimono Bathrobes</li>
                    <li>Jacquard Bathrobes</li>
                    <li>Hooded Bathrobes</li>
                    <li>Bathrobes with Slipers Embroidered</li>
                    <li>Appliqued Bathrobes</li>
                    <li>Single Side Printed Bathrobes</li>
                    <li>Kids Bathrobes.</li>
                </ul>
                </div>
            </div>
            </div>
        </section>

        <section className="position-relative">
            <div className="container py-4 z-1">
            <div className="row align-items-center justify-content-center">
                <div className="col-12 col-lg-4 order-0 order-lg-1">
                <img src={img3} alt=""
                    className="img-fluid mb-4 md-lg-0 w-100" />
                </div>
                <div className="col-12 col-lg-4 order-1 order-lg-0">
                <h2 className="display-3"> Institutional Towels</h2>
                <p>We are engaged in manufacturing, supplying and exporting of Institutional towels. We possess the capabilities of producing various colors and designs of institutional towels. As a result of our, commitment to exceptional product quality, we have reached new heights in the institutional textile business. Our institutional towels are known to be durable and comfortable to use. We manufacture towels in various sizes and weights for use in hotels, offices, hospitals, health clubs and other institutions.
                </p>
                </div>
            </div>
            </div>
        </section>
        </div>
    </div>
    </div>

    <div className="col-12">
                <div className="text-center">
                <div className="py-5 bg-white">
                    <h2 className=" font-weight-bold">Globally Connected</h2>
                    <div className="row">
                    <div className="col-12 col-lg-8 mx-auto">
                        <p>Hasham Towel, established 36 years ago, has built a strong reputation for producing
                             high-quality towels. With decades of expertise, Hasham Towel has perfected its manufacturing
                              processes, ensuring each product meets international standards. The company exports its 
                              premium towels to markets worldwide, consistently delivering on quality, reliability, and 
                              customer satisfaction. Their global reach and commitment to excellence have made Hasham Towel 
                              a trusted name in the industry.</p>
                        <img src={img4} className='img-fluid' alt="hasham map" />
                    </div>
                    </div>
                </div>
                </div>
            </div>
      </Fragment>
    )
  }
}

export default Story
