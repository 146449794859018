import React, { Component, Fragment } from 'react'
import img1 from '../../securedir/wp-content/themes/towellers/images/towels/2.jpg'
import img2 from '../../securedir/wp-content/themes/towellers/images/towels/4.jpg'
import img3 from '../../securedir/wp-content/themes/towellers/images/towels/7.jpg'
import img4 from '../../securedir/wp-content/themes/towellers/images/towels/8.jpg'
import img5 from '../../securedir/wp-content/themes/towellers/images/towels/1.jpg'
import img6 from '../../securedir/wp-content/themes/towellers/images/towels/3.jpg'
import img7 from '../../securedir/wp-content/themes/towellers/images/towels/6.jpg'
import img8 from '../../securedir/wp-content/themes/towellers/images/towels/9.jpg'


class Towel extends Component {
  render() {
    return (
      <Fragment>
        <section>
        <div className="container py-5 py-md-6">
            <div className="row">
            <div className="col-12">
                <h1 className="display-2">Towel</h1>
            </div>
            </div>
            <div className="row mt-3">
            <div className="col-auto p-0"></div>
            <div className="col-md pl-md-5">
                <p>Hasham Towel is a top exporter of terry products from Pakistan to global markets. 
                    We specialize in producing luxurious towels and lightweight healthcare items, catering 
                    to a wide range of customers. Our products are trusted by major hospitals and hotels 
                    across North America.</p>
                <p>Our extensive product line features towels, bathrobes, bath mats, slippers, kitchen towels,
                     tea towels, bedding, baby products, and waffle products. With this diverse product range,
                     we are able to meet the needs of various markets globally, ensuring high-quality and reliable 
                     products for all our customers.</p>
                <p>Our design team creates multiple collections annually, featuring unique products tailored to changing 
                    consumer trends, including design-focused, eco-friendly towels and highly absorbent cotton options.</p>
                <p><strong>Production Capacity: 205k kg monthly</strong></p>
            </div>
            </div>
        </div>
        </section>
        <section className="py-5 py-md-7 bg-secondary">
        <div className="container">
            <div className="row mb-5">
            <div className="col-12">
                <h2 className="font-weight-bold mb-0">Institutional Towels</h2>
            </div>
            </div>  
        </div>
        <div className="product-row">
            <div className="container">
            <div className="row">
                <div className="col-12 col-md-6 px-3 text-center">
                <div className="product-img-wrapper">
                    <img src={img1} className="w-100 img-fluid rounded"/>
                </div>
                </div>
                <div className="col-12 col-md-6 px-3 text-center mb-4">
                <div className="product-img-wrapper">
                    <img src={img2} className="w-100 img-fluid rounded"/>
                </div>
                </div>
            </div>
            </div>
        </div>
        <div className="product-row">
            <div className="container">
            <div className="row">
                <div className="col-12 col-md-6 px-3 text-center">
                <div className="product-img-wrapper">
                    <img src={img3} className="w-100 img-fluid rounded"/>
                </div>
                </div>
                <div className="col-12 col-md-6 px-3 text-center">
                <div className="product-img-wrapper">
                    <img src={img4} className="w-100 img-fluid rounded"/>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>

        <section className="py-5 py-md-7 bg-white">
        <div className="container">
            <div className="row mb-5">
            <div className="col-12">
                <h2 className="font-weight-bold mb-0">Retail Towels</h2>
            </div>
            </div>
        </div>
        <div className="product-row">
            <div className="container">
            <div className="row">
                <div className="col-12 col-md-6 px-3 text-center mb-4 mb-md-5">
                <div className="product-img-wrapper mb-1 mb-md-4">
                    <img src={img5} className="w-100 img-fluid rounded"/>
                </div>
                </div>
                <div className="col-12 col-md-6 px-3 text-center mb-4 mb-md-5">
                <div className="product-img-wrapper mb-1 mb-md-4">
                    <img src={img6} className="w-100 img-fluid rounded"/>
                </div>
                </div>
            </div>
            </div>
        </div>
        <div className="product-row">
            <div className="container">
            <div className="row">
                <div className="col-12 col-md-6 px-3 text-center mb-4 mb-md-5">
                <div className="product-img-wrapper mb-1 mb-md-4">
                    <img src={img7} className="w-100 img-fluid rounded"/>
                </div>
                </div>
                <div className="col-12 col-md-6 px-3 text-center mb-4 mb-md-5">
                <div className="product-img-wrapper mb-1 mb-md-4">
                    <img src={img8} className="w-100 img-fluid rounded"/>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>

      </Fragment>
    )
  }
}

export default Towel
