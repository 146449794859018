import React, { Component, Fragment } from 'react'
import img1 from '../../securedir/wp-content/themes/towellers/images/knit/b-1.png'
import img2 from '../../securedir/wp-content/themes/towellers/images/knit/b-2.jpg'
import img3 from '../../securedir/wp-content/themes/towellers/images/knit/b-3.jpg'
import img4 from '../../securedir/wp-content/themes/towellers/images/knit/b-4.jpg'
import img5 from '../../securedir/wp-content/themes/towellers/images/knit/b-5.png'


class Bathmates extends Component {
  render() {
    return (
      <Fragment>
        <section>
        <div className="container py-5 py-md-6">
            <div className="row mt-3">
            <div className="col-12 col-md-6 mb-4 mb-md-0 pr-md-5">
        <h1 className="display-2">Bath Mates</h1>
                <h3>Step into Comfort, Step into Luxury
                </h3>
                <p>As a staple of the home textile industry, Hasham Towel has been a leading supplier of Bath Mats 
                    for over 35 years with massive leaps innovating with creative designs and all required levels of quality.</p>
                <p>The organization has been fulfilling global demands for cotton bath mats without discrepancy for years and 
                    aims to keep meeting our customers needs.</p>
                <p>Catering to all sectors, Hasham Towel has been part of many organizations journey into creating a luxurious and 
                    fulfilling experience for their customers.</p>
            </div>
                <div className="col-12 col-md-6 mb-4 mb-md-0 pr-md-0">
                <div className="product-img-wrapper mb-1 mb-md-4">
                    <img src={img1}
                    className="img-fluid rounded"/>
                </div>
                </div>
            </div>
        </div>
        </section>
        <section className="pb-5 py-md-7 bg-white">
        <div className="product-row">
            <div className="container">
            <div className="row">
                <div className="col-12 col-md-6 px-3 text-center mb-4 mb-md-5">
                <div className="product-img-wrapper mb-1 mb-md-4">
                    <img src={img2} className="img-fluid rounded"/>
                </div>
                </div>
                <div className="col-12 col-md-6 px-3 text-center mb-4 mb-md-5">
                <div className="product-img-wrapper mb-1 mb-md-4">
                    <img src={img3} className="img-fluid rounded"/>
                </div>
                </div>
            </div>
            </div>
        </div>
        <div className="product-row">
            <div className="container">
            <div className="row">
                <div className="col-12 col-md-6 px-3 text-center mb-4 mb-md-5">
                <div className="product-img-wrapper mb-1 mb-md-4">
                    <img src={img4} className="img-fluid rounded"/>
                </div>
                </div>
                <div className="col-12 col-md-6 px-3 text-center mb-4 mb-md-5">
                <div className="product-img-wrapper mb-1 mb-md-4">
                    <img src={img5} className="img-fluid rounded"/>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>

      </Fragment>
    )
  }
}

export default Bathmates
