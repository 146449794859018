import React, { Component, Fragment } from 'react'
import i1 from '../../securedir/wp-content/themes/towellers/images/driving-innovation-forward.png'
import i2 from '../../securedir/wp-content/themes/towellers/images/weav.webp'
import i3 from '../../securedir/wp-content/themes/towellers/images/dye.jpg'
import i4 from '../../securedir/wp-content/themes/towellers/images/cut.jpg'
import i5 from '../../securedir/wp-content/themes/towellers/images/hib-1.png'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Zoom } from "react-reveal";


class OurProcess extends Component {
  render() {
    return (
      <Fragment>
        <section id="driving-innovation-forward" className="position-relative">
        <svg className="thread-anim thread-3 d-none d-lg-block" fill="none"
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2678 850" width="100%"
        height="850">
        <path
            d="M2703.3 158.4c-293.2-200.7-705.6-209.3-1006.9-21-187.5 117.1-325.7 296.8-486.8 448.3C1048.6 737.3 842 868 622 846.3c-112.3-11-216.5-60.7-324.6-92.7-108.1-32-231.7-44.6-328 14"
            stroke="#bebd50" stroke-width="3" className="thread-3-1"></path>
        </svg>
        <svg className="thread-anim m-thread-2 d-block d-lg-none" fill="none"
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 390 81" width="100%"
        height="81">
        <path d="M-38.5 80c57.7-41.3 230.2-111.6 459-62" stroke-width="2"
            stroke="#bebd50" stroke-miterlimit="10" stroke-linejoin="round"
            className="m-thread-2-1"></path>
        </svg>

        <div className="container">
        <h2>Our Process</h2>
        <div className="row align-items-center">
            <div className="col-1"></div>
            <div className="order-1 order-lg-0 col-12 col-lg-4">
            <h3>01 | WARPING/ SIZING</h3>
            <p>Warping/Sizing is an initial process to prepare the yarn. The method of applying a protective coating upon the surface of the yarn is termed warping. At our warp operation, we carefully combined the yarns from several cones and made a protective covering.</p>
            <p>
                <Link to="/our-facilities" className="btn btn-link">Our Production Suite
                <FontAwesomeIcon icon={faArrowRight} />
                </Link>
            </p>
            </div>
            <div className="order-0 order-lg-1 col-12 col-lg-6 mb-4 mb-lg-0 p-4 p-lg-5">
            <div className="img-border-wrapper tilt-left">
            <Zoom>
                <picture>
                <source media="(min-width:768px)"
                    srcset={i1} />
                <img className="img-fluid w-100"
                    src={i1}
                    alt="Driving innovation forward"/>
                </picture>
                </Zoom>
            </div>
            </div>
        </div>
        </div>

        <div className="container position-relative z-1">
        <svg className="thread-anim m-thread-3 d-block d-lg-none" fill="none"
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 390 59" width="100%"
            height="59">
            <path d="M423.9 23.5C359.6 53.6 177.2 91.3-38.8.8" stroke="#bebd50"
            stroke-miterlimit="10" stroke-linejoin="round" stroke-width="2"
            className="m-thread-3-1"></path>
        </svg>

        <div className="row align-items-center">
            <div className="col-1"></div>
            <div className="col-12 col-lg-5 mb-4 mb-lg-0 p-4 p-lg-5">
            <div className="img-border-wrapper tilt-right">
            <Zoom>
                <picture>
                <source media="(min-width:768px)"
                    srcset={i2} />
                <img className="img-fluid w-100 rounded"
                    src={i2} alt="Our impact"/>
                </picture>
                </Zoom>
            </div>
            </div>
            <div className="col-1"></div>
            <div className="order-1 order-lg-0 col-12 col-lg-4">
            <h3>02 | WEAVING</h3>
            <p>Weaving is the technique of creating a cloth by interlacing vertical and horizontal threads called warp and weft at accurate angles. Weaving is the most pivotal method of fabric manufacturing; we meticulously interlace warp and weft to make a fabric.</p>
            <p>
                <Link to="/our-facilities" className="btn btn-link">Our Production Suite <FontAwesomeIcon icon={faArrowRight} />
                </Link>
            </p>
            </div>
        </div>
        </div>
        </section>

        <section id="driving-innovation-forward" className="position-relative">
        <svg className="thread-anim thread-3 d-none d-lg-block" fill="none"
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2678 850" width="100%"
            height="850">
            <path
            d="M2703.3 158.4c-293.2-200.7-705.6-209.3-1006.9-21-187.5 117.1-325.7 296.8-486.8 448.3C1048.6 737.3 842 868 622 846.3c-112.3-11-216.5-60.7-324.6-92.7-108.1-32-231.7-44.6-328 14"
            stroke="#bebd50" stroke-width="3" className="thread-3-1"></path>
        </svg>
        <svg className="thread-anim m-thread-2 d-block d-lg-none" fill="none"
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 390 81" width="100%"
            height="81">
            <path d="M-38.5 80c57.7-41.3 230.2-111.6 459-62" stroke-width="2"
            stroke="#bebd50" stroke-miterlimit="10" stroke-linejoin="round"
            className="m-thread-2-1"></path>
        </svg>

        <div className="container">
            <div className="row align-items-center">
            <div className="col-1"></div>
            <div className="order-1 order-lg-0 col-12 col-lg-4">
                <h3>03 | DYEING</h3>
                <p>We pay close attention to each stage of manufacturing and ensure high-quality dyeing. We used the most upto date dying and drying machinery and processing for the best possible results. per day and the most up-to-date dying machinery. Reactive/Indanthrene dyes are utilized in the dyeing process as per the customer’s demand</p>
                <p>
                <Link to="/our-facilities" className="btn btn-link">Our Production Suite<FontAwesomeIcon icon={faArrowRight} /></Link>
                </p>
            </div>
        <div className="order-0 order-lg-1 col-12 col-lg-6 mb-4 mb-lg-0 p-4 p-lg-5">
        <div className="img-border-wrapper tilt-left">
        <Zoom>
          <picture>
            <source media="(min-width:768px)"
              srcset={i3} />
            <img className="img-fluid w-100 rounded"
              src={i3}
              alt="Driving innovation forward" />
          </picture>
          </Zoom>
        </div>
      </div>
    </div>
  </div>

  <div className="container position-relative z-1">
    <svg className="thread-anim m-thread-3 d-block d-lg-none" fill="none"
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 390 59" width="100%"
      height="59">
      <path d="M423.9 23.5C359.6 53.6 177.2 91.3-38.8.8" stroke="#bebd50"
        stroke-miterlimit="10" stroke-linejoin="round" stroke-width="2"
        className="m-thread-3-1"></path>
    </svg>

    <div className="row align-items-center">
      <div className="col-1"></div>
      <div className="col-12 col-lg-5 mb-4 mb-lg-0 p-4 p-lg-5">
        <div className="img-border-wrapper tilt-right">
        <Zoom>
          <picture>
            <source media="(min-width:768px)"
              srcset={i4} />
            <img className="img-fluid w-100 rounded"
              src={i4} alt="Our impact"/>
          </picture>
          </Zoom>
        </div>
      </div>
      <div className="col-1"></div>
        <div className="order-1 order-lg-0 col-12 col-lg-4">
        <h3>04 | STITCHING</h3>
        <p>Our meticulous attention to detail in stitching provides a seamless finish, guaranteeing a luxurious feel and exceptional absorbency. Experience the perfect blend of quality and comfort with every stitch.</p>
        <p>
          <Link to="/our-facilities" className="btn btn-link">Our Production Suite <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        </p>
      </div>
    </div>
  </div>
</section>

<div className="container">
    <div className="row align-items-center">
      <div className="col-1"></div>
      <div className="order-1 order-lg-0 col-12 col-lg-4">
        <h3>05 | PACKAGING</h3>
        <p>We have a separate packing department to examine the products before packing them. We packed products in seaworthy export boxes according to the buyer’s specifications, such as assorted or separated. Then after a thorough analysis, we get the product ready to ship.</p>
        <p>
          <Link to="/our-facilities" className="btn btn-link">Our Production Suite <FontAwesomeIcon icon={faArrowRight} /></Link>
        </p>
      </div>
      <div className="order-0 order-lg-1 col-12 col-lg-6 mb-4 mb-lg-0 p-4 p-lg-5">
        <div className="img-border-wrapper tilt-left">
        <Zoom>
          <picture>
            <source media="(min-width:768px)"
              srcset={i5} />
            <img className="img-fluid w-100 rounded"
              src={i5}
              alt="Driging innovation forward"/>
          </picture>
          </Zoom>
        </div>
      </div>
    </div>
  </div>
<div className="spacing pt-5 pt-lg-8"></div>
      </Fragment>
    )
  }
}

export default OurProcess
