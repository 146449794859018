import React, { Component } from 'react'
import TopNav from '../components/TopNav/TopNav'
import Facilities from '../components/Facilities/Facilities'
import Footer from '../components/Footer/Footer'

class Facility extends Component {
  render() {
    return (
      <div>
        <TopNav title="Our Facilities"/>
        <Facilities />
        <Footer />
      </div>
    )
  }
}

export default Facility
