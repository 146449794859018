import React, { Component, Fragment } from 'react'
import vid1 from '../../securedir/wp-content/themes/towellers/images/facilities/weaving.mp4'
import vid2 from '../../securedir/wp-content/themes/towellers/images/facilities/knitting.mp4'
import vid3 from '../../securedir/wp-content/themes/towellers/images/facilities/dyeing.mp4'
import vid4 from '../../securedir/wp-content/themes/towellers/images/facilities/printing.mp4'
import vid5 from '../../securedir/wp-content/themes/towellers/images/facilities/stitching.mp4'
import vid6 from '../../securedir/wp-content/themes/towellers/images/facilities/utilities.mp4'

class Facilities extends Component {
  render() {
    return (
      <Fragment>
      <section className="my-5 my-lg-7">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="display-2 font-weight-bold text-primary">Our Production Suite</h1>
              <div className="row">
                <div className="col-12 col-lg-10">
                  <p className="font-serif h3">
                    Hasham Towel uses the latest technology and equipment along with skilled workmanship to create textiles for all its customers across the globe.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div id="story" className="position-relative container">

        <svg className="thread-anim thread-production d-none d-lg-block" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1795 3671" width="100%" height="100%">
          <path
            d="M1854.5 3252c-416.5 0-1329.5 753-1539 235.5s248.3-707.8 407-755C737 2458 295 2103.5 127 2062c163-12.5 488.5-146.1 486.5-580.5S225.5 996.3 1 947.5C305.7 993.3 848.4 820.4 836 384 823.6-52.4 1184.5-10.5 1343 17.5s547.5 193 547.5 657"
            stroke="#bdbc50" strokeWidth="2" strokeMiterlimit="10" strokeLinejoin="round" className="thread-production-1"
          ></path>
        </svg>

        <div className="row">
          <div className="col-12">
            <section className="position-relative">
              <div className="container py-4 z-1">
                <div className="row align-items-center justify-content-center">
                  <div className="col-12 col-md order-0 order-lg-1 px-lg-5 position-relative video-wrapper">
                  <video
                    className="h-100 w-100 object-cover rounded"
                    muted
                    autoPlay
                    loop>
                    <source src={vid1} type="video/mp4" />
                  </video>
                  </div>
                  <div className="col-12 col-lg-3 order-1 order-lg-0">
                    <h2>Weaving</h2>
                    <p>Waving is done on the total area of 30,000 Sq. ft. on the ground floor there are looms and on the basement woven fabric are collected.</p>
                  </div>
                </div>
              </div>
            </section>

            <section className="position-relative">
              <div className="container py-4">
                <div className="row align-items-center justify-content-center py-5">
                  <div className="col-12 col-md px-lg-5 position-relative video-wrapper">
                  <video
                    className="h-100 w-100 object-cover rounded"
                    muted
                    autoPlay
                    loop>
                    <source src={vid2} type="video/mp4" />
                  </video>
                  </div>
                  <div className="col-12 col-lg-3">
                    <h2>Embroidery</h2>
                    <p>All kinds of embroideries are done with a touch of art, if required by our valued customers.</p>
                  </div>
                </div>
              </div>
            </section>

            <section className="position-relative">
              <div className="container py-4 z-1">
                <div className="row align-items-center justify-content-center">
                  <div className="col-12 col-md order-0 order-lg-1 px-lg-5 position-relative video-wrapper">
                  <video
                    className="h-100 w-100 object-cover rounded"
                    muted
                    autoPlay
                    loop>
                    <source src={vid3} type="video/mp4" />
                  </video>
                  </div>
                  <div className="col-12 col-lg-3 order-1 order-lg-0">
                    <h2>Dyeing & Bleaching</h2>
                    <p>Dyeing is done on the most sophisticated standards with state-of-the-art machinery. We Dye on machines that are: Eco friendly, Azo free, High Fixation Value, Good washing off properties, High fastness level, Highly Absorbent.</p>
                  </div>
                </div>
              </div>
            </section>

            <section className="position-relative">
              <div className="container py-4">
                <div className="row align-items-center justify-content-center py-5">
                  <div className="col-12 col-md px-lg-5 position-relative video-wrapper">
                  <video
                    className="h-100 w-100 object-cover rounded"
                    muted
                    autoPlay
                    loop>
                    <source src={vid4} type="video/mp4" />
                  </video>
                  </div>
                  <div className="col-12 col-lg-3">
                    <h2>Printing
                    </h2>
                    <p>Add personality to your textiles with the printing facility at Hasham Towel. 
                      Create engaging patterns with screen printing, heat press printing, direct-to-garment 
                      (DTG), and dye sublimation..</p>
                  </div>
                </div>
              </div>
            </section>

            <section className="position-relative">
              <div className="container py-4 z-1">
                <div className="row align-items-center justify-content-center">
                  <div className="col-12 col-md order-0 order-lg-1 px-lg-5 position-relative video-wrapper">
                  <video
                    className="h-100 w-100 object-cover rounded"
                    muted
                    autoPlay
                    loop>
                    <source src={vid5} type="video/mp4" />
                  </video>
                  </div>
                  <div className="col-12 col-lg-3 order-1 order-lg-0">
                    <h2>Stitching</h2>
                    <p>The department export the complete cutting and stitching section for towels and bathrobes.</p>
                  </div>
                </div>
              </div>
            </section>

            <section className="position-relative">
              <div className="container py-4">
                <div className="row align-items-center justify-content-center py-5">
                  <div className="col-12 col-md px-lg-5 position-relative video-wrapper">
                    <video
                    className="h-100 w-100 object-cover rounded"
                    muted
                    autoPlay
                    loop>
                    <source src={vid6} type="video/mp4" />
                  </video>
                  </div>
                  <div className="col-12 col-lg-3">
                    <h2>Utilities</h2>
                    <p>Sustainability and efficiency are the top priority at Hasham Towel. By generating power, with a boiler and generators, the facility ensures a dependable and uninterrupted flow of electricity.</p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      </Fragment>


    )
  }
}

export default Facilities
