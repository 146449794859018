import React, { Component } from 'react'
import TopNav from '../components/TopNav/TopNav'
import Bathmates from '../components/Bathmates/Bathmates'
import Footer from '../components/Footer/Footer'

class BathmatePage extends Component {
  render() {
    return (
      <div>
        <TopNav title="Bath Mates" />
        <Bathmates />
        <Footer />
      </div>
    )
  }
}

export default BathmatePage
