import React, { Component, Fragment } from 'react'
import img1 from '../../securedir/wp-content/themes/towellers/images/baby/blank-1 (1).jpeg'
import img2 from '../../securedir/wp-content/themes/towellers/images/baby/blank-1 (2).jpeg'

class TarryBlanket extends Component {
  render() {
    return (
      <Fragment>
        <section>
        <div className="container py-5 py-md-6">
            <div className="row">
            <div className="col-12">
                <h1 className="display-2">Tarry Blanket</h1>
            </div>
            </div>
            <div className="row mt-3">
            <div className="col-12 col-md-3 mb-4 mb-md-0">
                <h3>Tarry Blanket made <br className="d-none d-md-block" />with love
                and care</h3>
            </div>
            <div className="col-auto border-right p-0"></div>
            <div className="col-md pl-md-5">
                <p>As a manufacturer of the Tarry Blanket, we take pride in crafting premium-quality, 
                    ultra-soft blankets designed for comfort and warmth. Made from high-grade, durable 
                    materials, the Tarry Blanket provides an unparalleled cozy experience, perfect for 
                    all seasons. Our blankets are thoughtfully engineered to combine luxury with long-lasting 
                    durability, making them the ideal choice for homes, outdoor activities, or travel.</p>
            </div>
            </div>
        </div>
        </section>
        <section className="pb-5 py-md-7 bg-white">
        <div className="product-row">
            <div className="container">
            <div className="row">
                <div className="col-12 col-md-6 px-3 text-center mb-4 mb-md-5">
                <div className="product-img-wrapper mb-1 mb-md-4">
                    <img src={img1} className="w-100 img-fluid rounded-3"/>
                </div>
                </div>
                <div className="col-12 col-md-6 px-3 text-center mb-4 mb-md-5">
                <div className="product-img-wrapper mb-1 mb-md-4">
                    <img src={img2} className="w-100 img-fluid rounded-3"/>
                </div>
                </div>
            </div>
            </div>
        </div>
        
        </section>

      </Fragment>
    )
  }
}

export default TarryBlanket
