import React, { Component, Fragment } from 'react'
import TopNav from '../components/TopNav/TopNav'
import Slippers from '../components/Slippers/Slippers'
import Footer from '../components/Footer/Footer'

class SlipperPage extends Component {
  render() {
    return (
      <Fragment>
        <TopNav title="Slippers"/>
        <Slippers />
        <Footer />
      </Fragment>
    )
  }
}

export default SlipperPage
