import React, { Component, Fragment } from 'react'
import towel from '../../securedir/wp-content/themes/towellers/images/tow.png'
import bathrobe from '../../securedir/wp-content/themes/towellers/images/bath.png'
import bathmate from '../../securedir/wp-content/themes/towellers/images/knit/b-1.png'
import slipper from '../../securedir/wp-content/themes/towellers/images/slippers.png'
import kitchen from '../../securedir/wp-content/themes/towellers/images/p-towels.png'
import tea from '../../securedir/wp-content/themes/towellers/images/p-apparel.png'
import bedding from '../../securedir/wp-content/themes/towellers/images/p-knit.png'
import baby from '../../securedir/wp-content/themes/towellers/images/p-baby.png'
import waffle from '../../securedir/wp-content/themes/towellers/images/waffle.png'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { Zoom } from "react-reveal";


export class OurProducts extends Component {
  render() {
    return (
      <Fragment>
        <section className="bg-secondary position-relative">
        <svg className="thread-anim thread-1 d-none d-lg-block" fill="none"
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2678 88" width="2678"
            height="88">
            <path
            d="M2678 39.6c-77.2 48.5-174.4 54-264.8 42-90.4-12.1-178-40.1-268.3-53.6-226.8-33.8-456.7 25.6-686 25.2-243.2-.3-484.3-67.7-726.7-48.9C600.7 14.5 472.4 50 341.4 65S70.4 72-42 3.3"
            stroke="#fff" stroke-width="2" className="svg-elem-1"></path>
        </svg>

        <div className="container py-5 py-lg-7">
        <h2 className="mb-5 text-center text-lg-left">Our Products</h2>
        <div className="row">
            <div className="col-4 text-center d-flex flex-column justify-content-end">
            <Link className="product-link-hover" to="/towels">
                <Zoom>
                <img src={towel} className="img-fluid w-100"/>
                <p className="mt-4 font-weight-bold">Towels</p>
                </Zoom>  
            </Link>  
            </div>
            
            <div className="col-4 text-center d-flex flex-column justify-content-end">
            <Link className="product-link-hover" to="/bathrobe">
            <Zoom>
                <img width="400" src={bathrobe} className="img-fluid" />
                <p className="mt-4 font-weight-bold">Bathrobes</p>
                </Zoom>
            </Link>
            </div>
            <div className="col-4 text-center d-flex flex-column justify-content-end">
            <Link className="product-link-hover" to="/bathmate">
            <Zoom>
                <img src={bathmate} className="img-fluid w-100" />
                <p className="mt-4 font-weight-bold">Bath Mates</p>
                </Zoom>
            </Link>
            </div>
        </div>

        <div className="row mt-4">
            <div className="col-4 text-center d-flex flex-column justify-content-end">
            <Link className="product-link-hover" to="/towels">
            <Zoom>
                <img width="300" src={slipper} className="img-fluid"/>
                <p className="mt-4 font-weight-bold">Slippers</p>
                </Zoom>
            </Link>
            </div>
            <div className="col-4 text-center d-flex flex-column justify-content-end">
            <Link className="product-link-hover" to="/sauna-kits">
            <Zoom>
                <img width="250" src={kitchen} className="img-fluid"/>
                <p className="mt-4 font-weight-bold">Kitchen Towels</p>
                </Zoom>
            </Link>
            </div>
            <div className="col-4 text-center d-flex flex-column justify-content-end">
            <Link className="product-link-hover" to="/turban">
            <Zoom>
                <img width="300" src={tea} className="img-fluid"/>
                <p className="mt-4 font-weight-bold">Tea towels</p>
                </Zoom>
            </Link>
            </div>
        </div>
        <div className="row mt-4">
            <div className="col-4 text-center d-flex flex-column justify-content-end">
            <Link className="product-link-hover" to="/tarry-blanket">
            <Zoom>
                <img width="300" src={bedding} className="img-fluid"/>
                <p className="mt-4 font-weight-bold">Bedding</p>
                </Zoom>
            </Link>
            </div>
            <div className="col-4 text-center d-flex flex-column justify-content-end">
            <Link className="product-link-hover" to="/turban">
            <Zoom>
                <img width="300" src={baby} className="img-fluid"/>
                <p className="mt-4 font-weight-bold">Baby Products</p>
                </Zoom>
            </Link>
            </div>
            <div className="col-4 text-center d-flex flex-column justify-content-end">
            <Link className="product-link-hover" to="/towels">
            <Zoom>
                <img width="300" src={waffle} className="img-fluid"/>
                <p className="mt-4 font-weight-bold">Waffle Products</p>
                </Zoom>
            </Link>
            </div>
        </div>
        </div>
        </section>
      </Fragment>
    )
  }
}

export default OurProducts
