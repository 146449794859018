import React, { Component, Fragment } from 'react'
import img1 from '../../securedir/wp-content/themes/towellers/images/baby/turban-1.jpeg'
import img2 from '../../securedir/wp-content/themes/towellers/images/baby/turban-2.jpeg'

class Turban extends Component {
  render() {
    return (
      <Fragment>
        <section>
        <div className="container py-5 py-md-6">
            <div className="row">
            <div className="col-12">
                <h1 className="display-2"> Turban/Hair Holder</h1>
            </div>
            </div>
            <div className="row mt-3">
            <div className="col-12 col-md-3 mb-4 mb-md-0">
                <h3>Turban towels made <br className="d-none d-md-block" />with love
                and care</h3>
            </div>
            <div className="col-auto border-right p-0"></div>
            <div className="col-md pl-md-5">
                <p>Hasham Towel focus on delivering high-quality, super-absorbent towels 
                    designed for convenience and comfort. Our Turban Towels are made from soft, 
                    lightweight materials that dry hair quickly without causing damage or frizz. 
                    With an easy-to-use design, they securely wrap around the head, making them 
                    ideal for daily use after showers, baths, or spa treatments. Tailored to suit 
                    all hair types, our towels combine functionality with style, providing a luxurious 
                    solution for efficient hair drying while promoting healthier hair.</p>
            </div>
            </div>
        </div>
        </section>

        <section className="pb-5 py-md-7 bg-white">
        <div className="product-row">
            <div className="container">
            <div className="row">
                <div className="col-12 col-md-6 px-3 text-center mb-4 mb-md-5">
                <div className="product-img-wrapper mb-1 mb-md-4">
                    <img src={img1} className="w-100 img-fluid"/>
                </div>
                </div>
                <div className="col-12 col-md-6 px-3 text-center mb-4 mb-md-5">
                <div className="product-img-wrapper mb-1 mb-md-4">
                    <img src={img2} className="w-100 img-fluid"/>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>

      </Fragment>
    )
  }
}

export default Turban
