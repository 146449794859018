import React, { Component, Fragment } from 'react'
import c2 from '../../securedir/wp-content/themes/towellers/images/clients/c-2.png'
import c3 from '../../securedir/wp-content/themes/towellers/images/clients/c-3.png'
import c4 from '../../securedir/wp-content/themes/towellers/images/clients/c-4.png'
import c5 from '../../securedir/wp-content/themes/towellers/images/clients/c-5.png'
import c6 from '../../securedir/wp-content/themes/towellers/images/clients/c-6.png'
import c7 from '../../securedir/wp-content/themes/towellers/images/clients/c-7.png'
import c8 from '../../securedir/wp-content/themes/towellers/images/clients/c-8.png'
import c12 from '../../securedir/wp-content/themes/towellers/images/clients/c-12.png'
import c14 from '../../securedir/wp-content/themes/towellers/images/clients/c-14.png'
import c15 from '../../securedir/wp-content/themes/towellers/images/clients/c-15.png'
import c16 from '../../securedir/wp-content/themes/towellers/images/clients/c-16.png'
import c17 from '../../securedir/wp-content/themes/towellers/images/clients/c-17.png'
import { Link } from 'react-router-dom/cjs/react-router-dom'

class Logos extends Component {
  render() {
    return (
      <Fragment>
        <section id="seriously-sustainable">
        <div className="container py-5 py-lg-7">
            <div className="row align-items-center justify-content-between">
            <div className="col-12 col-lg-4 mb-4 mb-lg-0">
                <h2>Profoundly <br/>eco-conscious</h2>
                <p>Hasham Towel is to produce products that exceed the expectations of our customers & meet the highest international standards of quality</p>
                <p>
                <Link to="/sustainability" className="btn btn-link">Learn More <span className="material-icons">
                    east
                    </span></Link>
                </p>
            </div>
            <div className="col-12 col-lg-8 pl-lg-5">
                <div className="row align-items-center justify-content-center">
                <img className="order-0 order-lg-1 col-6 col-lg-auto client-logo"
                    src={c2}alt="hasham towel" />
                <img className="order-1 order-lg-2 col-6 col-lg-auto client-logo"
                    src={c3}alt="hasham towel" />
                <img className="order-3 order-lg-3 col-6 col-lg-auto client-logo"
                    src={c4}alt="hasham towel" />
                <img className="order-4 order-lg-4 col-6 col-lg-auto client-logo"
                    src={c5}alt="hasham towel" />
                <img className="order-6 order-lg-5 col-6 col-lg-auto client-logo"
                    src={c6}alt="hasham towel" />
                <img className="order-7 order-lg-6 col-6 col-lg-auto client-logo"
                    src={c7}alt="hasham towel" />
                <img className="order-5 order-lg-7 col-6 col-lg-auto client-logo"
                    src={c8}alt="hasham towel" />
                <img className="order-1 order-lg-2 col-6 col-lg-auto client-logo"
                    src={c17} alt="hasham towel" />
                <img className="order-4 order-lg-4 col-6 col-lg-auto client-logo"
                    src={c12} alt="hasham towel" />
                <img className="order-7 order-lg-6 col-6 col-lg-auto client-logo"
                    src={c14} alt="hasham towel" />
                <img className="order-5 order-lg-7 col-6 col-lg-auto client-logo"
                    src={c15} alt="hasham towel" />
                <img className="order-5 order-lg-7 col-6 col-lg-auto client-logo"
                    src={c16} alt="hasham towel" />
                </div>
            </div>
            </div>
        </div>
        </section>

        <section id="cta" className="overflow-hidden">
        <div className="container py-5 py-lg-7 position-relative">
            <svg className="thread-anim thread-4" fill="none"
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 741 325" width="505"
            height="325">
            <path
                d="M740 245.3C675.2 112.8 538.7 18 391.6 3.2c-95.2-9.6-195 14-270.8 72.4C45.2 134-3.6 228.6 1.3 324"
                stroke="#bdbc50" stroke-width="3" stroke-miterlimit="10"
                stroke-linejoin="round" className="thread-4-1"></path>
            </svg>

            <div className="row">
            <div className="col-12 d-lg-flex align-items-center">
                <h2 className="m-0">Become a part of the Hasham Towel.</h2>
                <Link to="/contact-us"
                className="btn bg-secondary text-white py-3 px-4 mt-4 mt-lg-0 ml-lg-4 text-nowrap">Get
                in
                touch</Link>
            </div>
            </div>
        </div>
        </section>

      </Fragment>
    )
  }
}

export default Logos
