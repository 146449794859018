import React, { Component, Fragment } from 'react'
import HomePage from '../pages/HomePage';
import AboutUsPage from '../pages/AboutUsPage';
import OurStory from '../pages/OurStory';
import SustainPage from '../pages/SustainPage';
import ContactPage from '../pages/ContactPage';
import Facility from '../pages/Facility';
import TowelPage from '../pages/TowelPage';
import Bath from '../pages/Bath';
import BathmatePage from '../pages/BathmatePage';
import TarryBlanketPage from '../pages/TarryBlanketPage';
import TurbanPage from '../pages/TurbanPage';
import SaunaPage from '../pages/SaunaPage';
import { Route, Switch } from 'react-router-dom/cjs/react-router-dom';
import SlipperPage from '../pages/SlipperPage';

export class AppRouter extends Component {
  render() {
    return (
        <Fragment>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/about-us" component={AboutUsPage} />
          <Route exact path="/our-story" component={OurStory} />
          <Route exact path="/sustainability" component={SustainPage} />
          <Route exact path="/our-facilities" component={Facility} />
          <Route exact path="/contact-us" component={ContactPage} />
          <Route exact path="/towels" component={TowelPage} />
          <Route exact path="/bathrobe" component={Bath} />
          <Route exact path="/bathmate" component={BathmatePage} />
          <Route exact path="/terry-blanket" component={TarryBlanketPage} />
          <Route exact path="/turban" component={TurbanPage} />
          <Route exact path="/sauna-kits" component={SaunaPage} />
          <Route exact path="/slippers" component={SlipperPage} />
        </Switch>
      </Fragment>
    )
  }
}

export default AppRouter
