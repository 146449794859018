import React, { Component, Fragment } from 'react'
import TopNav from '../components/TopNav/TopNav'
import Sustain from '../components/Sustain/Sustain'
import Footer from '../components/Footer/Footer'

class SustainPage extends Component {
  render() {
    return (
      <Fragment>
        <TopNav title="Sustainability"/>
        <Sustain/>
        <Footer />
      </Fragment>
    )
  }
}

export default SustainPage
