import React, { Component, Fragment } from 'react'
import img1 from '../../securedir/wp-content/themes/towellers/images/hib-1.png'
import img2 from '../../securedir/wp-content/themes/towellers/images/hib-3.png'
import img3 from '../../securedir/wp-content/themes/towellers/images/hib-2.png'
import img4 from '../../securedir/wp-content/themes/towellers/images/hib-4.jpg'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'


class OurStory extends Component {
  render() {
    return (
      <Fragment>
        <div className="spacing pt-5 pt-lg-8"></div>
        <section id="how-it-began" className="bg-white">
        <div className="container position-relative">
            <div className="row align-items-center">
            <svg className="thread-anim thread-2 d-none d-lg-block" fill="none"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2678 395" width="100%">
                <path
                d="M2819 369.3c-22.3-104.8-130.1-174-236.7-185.5-106.5-11.5-212.2 22.3-313.4 57.6-101.2 35.3-204.7 73.1-311.8 69.9-104.3-3.1-205.8-45.3-310-39-158.6 9.8-298.8 130.2-457.4 121.2-38.9-2.1-78.8-13.1-107.4-39.5-28.6-26.3-42.9-70.4-26.7-105.8 29.5-64.4 138.5-73.7 147.5-143.9 5.5-43-37-80.6-80.4-84.2-43.2-3.7-84.6 17.4-120.7 41.5-36.1 24.1-70.6 52.2-111.8 65.9-41.2 13.6-92.5 8.7-119.8-25C743.2 68.7 760 5.4 803.1 1.2c41.2-4 70.5 48.2 57.1 87.3-13.4 39.2-53.8 62.7-93.9 73-93 23.7-190-6.6-283.3-29.4-93.2-22.8-198.4-36.5-279.6 14.7-26.5 16.7-49.7 41.1-57 71.5-7.3 30.4 5.3 66.7 33.7 79.8 28.3 13.1 68.6-6.7 69.6-38 1-37.2-47-57.3-83.2-49-36.3 8.5-66.5 33.5-102 44.8A144 144 0 0 1-92 203.6a144.1 144.1 0 0 1 1.8-164.8"
                stroke="#bebd50" stroke-width="2" className="thread-2-1"></path>
            </svg>
            <svg className="thread-anim m-thread-1 d-block d-lg-none" fill="none"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 390 140" width="100%"
                height="40%">
                <path
                d="M-35 139C-21.8 84.2 33.7-13.3 150.5 35.5c116.8 48.8 226-2.7 266-34.5"
                stroke="#bebd50" stroke-miterlimit="10" stroke-width="2"
                stroke-linejoin="round" className="m-thread-1-1"></path>
            </svg>

            <div className="col-1"></div>
            <div className="col-12 col-lg-5 px-lg-4 mb-4 mb-lg-0">
                <div className="d-none d-lg-flex gap-div">
                <div className="d-flex flex-column mt-3">
                    <img className="rounded ml-2" width="200"
                    src={img1} alt="HIB 1"/>
                    <img
                    src={img2} className="pl-5 mt-3"
                    alt="HIB 1"/>
                </div>  
                <div className="mt-n5">
                    <img
                    src={img3} alt="HIB 1"/>
                    <img className="rounded mt-4" width="200"
                     src={img4} alt="HIB 1"/>
                </div>
                </div>
            </div>
            <div className="col-12 col-lg-4">
                <h2>Our Story</h2>
                <p>Since the establishment of Hasham Towels in 1988, the company has become well known for its continuous efforts to develop latest designs in towels and bathrobes. For 25 years, the company has achieved a significant level of etxport sales and has become well known globally for supplying quality products. The company has grown considerably since its early days and has established its mark in bath accessories. An inherent desire for technical excellence leads Hasham to the achievement of ISO 9002 Certification. We successfully participate in a number of international trade fairs throughout the year including the hemitextil, Frankfurt, displaying the best towels and bathrobes of all types and sizes. OEKOTEX Certificate ensures that all our products are free from harmful substances and can be used confidently.</p>
                <p>
                <Link to="/our-story" className="btn btn-link">The Hasham Towel Story <FontAwesomeIcon icon={faArrowRight} /></Link>
                </p>
            </div>
            </div>
        </div>
        </section>


        <div className="spacing pt-5 pt-lg-8"></div>

        

      </Fragment>
    )
  }
}

export default OurStory
