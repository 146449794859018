import React, { Component } from 'react'
import TopNav from '../components/TopNav/TopNav'
import Footer from '../components/Footer/Footer'
import TarryBlanket from '../components/TarryBlanket/TarryBlanket'

class TarryBlanketPage extends Component {
  render() {
    return (
      <div>
        <TopNav title="Terry Blanket" />
        <TarryBlanket />
        <Footer />
      </div>
    )
  }
}

export default TarryBlanketPage
