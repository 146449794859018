import React, { Component, Fragment } from 'react'
import foot from '../../securedir/wp-content/themes/towellers/images/new-logo.png'
import { Link } from 'react-router-dom/cjs/react-router-dom'

class Footer extends Component {
  render() {
    return (
      <Fragment>
        <footer className="site-footer" id="footer">
        <div className="pt-5 pb-5">
            <div className="container py-md-5">
            <div className="row">
                <div className="col-12 col-md-2 footer-col">
                <img src={foot} className="img-fluid w-100" alt="Logo Insignia" />
                </div>
                <div className="col-12 col-md-2 footer-col">
                <ul className="list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/about-us">About Us</Link></li>
                    <li><Link to="/our-story">Our Story</Link></li>
                    <li><Link to="/our-facilities">Our Facilities</Link></li>
                    <li><Link to="/contact-us">Contact Us</Link></li>
                </ul>
                </div>
                <div className="col-12 col-md-2 footer-col">
                <ul className="list-unstyled">
                    <li><Link to="/towels">Towels</Link></li>
                    <li><Link to="/bathrobe">Bathrobe</Link></li>
                    <li><Link to="/bathmate">Bath Mates</Link></li>
                    <li><Link to="/terry-blanket">Terry Blanket</Link></li>
                </ul>
                </div>
                <div className="col-12 col-md-2 footer-col">
                <p>+92 (21) 35055385<br/>
                <Link to="mailto:ht@hashamtowels.com">ht@hashamtowels.com</Link></p>
                <p>Plot # 126, Sector 24, Korangi Industrial Area, Karachi</p>
                <ul className="list-inline list-unstyled">
                    <li className="list-inline-item">
                    <a href='https://www.facebook.com/profile.php?id=100081379067613&mibextid=ZbWKwL'><i className="fa fa-facebook"></i></a>
                    </li>
                    <li className="list-inline-item">
                    <Link target="_blank" to="/"><i className="fa fa-linkedin"></i></Link>
                    </li>
                    <li className="list-inline-item">
                    <a href='https://www.instagram.com/hasham_towel'><i className="fa fa-instagram"></i></a>
                    </li>
                </ul>
                <p>Copyright Hasham Towel Limited</p>
                </div>
                <div className="col-12 col-md-2 footer-col">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3620.7676349062203!2d67.1258865744288!3d24.837618846173015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33bba1fe00da3%3A0x426baf9284c0af1d!2sPlot%20126%2C%20Mehran%20Town%20Sector%2024%20Korangi%20Industrial%20Area%2C%20Karachi%2C%20Karachi%20City%2C%20Sindh%2074500%2C%20Pakistan!5e0!3m2!1sen!2s!4v1718027990908!5m2!1sen!2s" width="300" height="200" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
            </div>
        </div>
        </footer>
      </Fragment>
    )
  }
}

export default Footer
