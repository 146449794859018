import React, { Component } from 'react'
import TopNav from '../components/TopNav/TopNav'
import Sauna from '../components/Sauna/Sauna'
import Footer from '../components/Footer/Footer'

class SaunaPage extends Component {
  render() {
    return (
      <div>
        <TopNav title="Sauna Kits"/>
        <Sauna />
        <Footer />
      </div>
    )
  }
}

export default SaunaPage
