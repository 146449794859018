import React, { Component } from 'react'
import TopNav from '../components/TopNav/TopNav'
import Contact from '../components/Contact/Contact'
import Footer from '../components/Footer/Footer'

class ContactPage extends Component {
  render() {
    return (
      <div>
        <TopNav title="Contact Us"/>
        <Contact />
        <Footer />
      </div>
    )
  }
}

export default ContactPage
