import React, { Component } from 'react'
import Capacity from "../components/Capacity/Capacity";
import Footer from "../components/Footer/Footer";
import Logos from "../components/Logos/Logos";
import OurProcess from "../components/OurProcess/OurProcess";
import OurProducts from "../components/OurProducts/OurProducts";
import OurStory from "../components/OurStory/OurStory";
import VideoMainHome from "../components/VideoMainHome/VideoMainHome";
import TopNav from '../components/TopNav/TopNav';

class HomePage extends Component {
  componentDidMount() {
    // Check if the page has already been refreshed in this session
    if (!window.performance || window.performance.navigation.type !== 1) {
      window.location.reload();
    }
  }

  render() {
    return (
      <div>
        <TopNav title="Hasham Towel"/>
        <VideoMainHome />
        <OurProducts />
        <OurStory />
        <OurProcess />
        <Capacity />
        <Logos />
        <Footer />
      </div>
    )
  }
}

export default HomePage
