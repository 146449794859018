import React, { Component, Fragment } from 'react'
import img1 from '../../securedir/wp-content/themes/towellers/images/baby/slipper.jpeg'
import img2 from '../../securedir/wp-content/themes/towellers/images/baby/slip2.jpeg'
import img3 from '../../securedir/wp-content/themes/towellers/images/baby/baby-3.jpg'
import img4 from '../../securedir/wp-content/themes/towellers/images/baby/baby-3.jpg'

class Slippers extends Component {
  render() {
    return (
        <Fragment>
          <section>
          <div className="container py-5 py-md-6">
              <div className="row">
              <div className="col-12">
                  <h1 className="display-2">Slippers</h1>
              </div>
              </div>
              <div className="row mt-3">
              <div className="col-auto p-0"></div>
              <div className="col-md pl-md-5">
                  <p>We pride ourselves on creating high-quality, comfortable 
                    footwear designed for everyday relaxation. Our slippers are crafted 
                    using soft, durable materials that provide cushioning and support, 
                    ensuring a cozy experience with every step. Designed with both style 
                    and function in mind, our slippers are perfect for indoor and light 
                    outdoor use, offering warmth and comfort year-round. With various designs 
                    to suit individual tastes, our slippers bring a touch of luxury to your 
                    home life while promoting relaxation and well-being.</p>
              </div>
              </div>
          </div>
          </section>
          <section className="pb-5 py-md-7 bg-white">
          <div className="product-row">
              <div className="container">
              <div className="row">
                  <div className="col-12 col-md-6 px-3 text-center mb-4 mb-md-5">
                  <div className="product-img-wrapper mb-1 mb-md-4">
                      <img src={img1} className="w-100 img-fluid"/>
                  </div>
                  </div>
                  <div className="col-12 col-md-6 px-3 text-center mb-4 mb-md-5">
                  <div className="product-img-wrapper mb-1 mb-md-4">
                      <img src={img2} className="w-100 img-fluid"/>
                  </div>
                  </div>
              </div>
              </div>
          </div>
          </section>
  
        </Fragment>
    )
  }
}

export default Slippers
