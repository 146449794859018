import React, { Component, Fragment } from 'react'
import img1 from '../../securedir/wp-content/themes/towellers/images/apparel/apparel.png'
import img2 from '../../securedir/wp-content/themes/towellers/images/apparel/apparel-2.png'
import img3 from '../../securedir/wp-content/themes/towellers/images/apparel/apparel-3.jpg'
import img4 from '../../securedir/wp-content/themes/towellers/images/apparel/apparel-4.jpeg'
import img5 from '../../securedir/wp-content/themes/towellers/images/apparel/apparel-1.png'

class Bathrobe extends Component {
  render() {
    return (
      <Fragment>
        <section>
        <div className="container py-5 py-md-6">
            <div className="row mt-3">
            <div className="col-12 col-md-6 mb-4 mb-md-0 pr-md-5">
                <h1 className="display-2">Bathrobes</h1>
                <h3>Comfortable, Cozy and Carefree Cotton</h3>
                <p>Hasham Towel is one of the leading bathrobe manufacturers in Pakistan. The company implements cutting 
                    edge processes and a through top to bottom setup to ensure the highest quality in Bathrobes.</p>
                <p>The organisation proudly leads the industry in offering the newest and most specialized design options 
                    such as Jacquard Bathrobes, Yarn Dyed Bathrobes and Waffle Bathrobes among many more.</p>
                <p>Establishing rigorous and impactful quality assurance and operating procedures has allowed Hasham Towel 
                    to deliver unmatched quality to its esteemed customers to ensure a worry free workflow.</p>
                <p>Being a part of the Better Cotton Initiative and many more, Hasham Towel aims to explore further possibilities 
                    with the sustainable and renewable cotton products.</p>
                <p><strong>Production Capacity: 500,000 Bathrobes annually</strong>
                </p>
            </div>
                <div className="col-12 col-md-6 mb-4 mb-md-0 pr-md-5">
                <div className="product-img-wrapper mb-1 mb-md-4">
                    <img src={img4} className="w-100 img-fluid"/>
                </div>
                </div>
            </div>
        </div>
        </section>

        <style>
        
        </style>

        <section className="pb-5 py-md-7 bg-white">
        <div className="product-row">
            <div className="container">
            <div className="row">
                <div className="col-12 col-md-6 px-3 text-center mb-4 mb-md-5">
                <div className="product-img-wrapper mb-1 mb-md-4">
                    <img src={img5} className="w-100 img-fluid"/>
                </div>
                </div>
                <div className="col-12 col-md-6 px-3 text-center mb-4 mb-md-5">
                <div className="product-img-wrapper mb-1 mb-md-4">
                    <img src={img2} className="w-100 img-fluid"/>
                </div>
                </div>
            </div>
            </div>  
        </div>
        <div className="product-row">
            <div className="container">
            <div className="row">
                <div className="col-12 col-md-6 px-3 text-center mb-4 mb-md-5">
                <div className="product-img-wrapper mb-1 mb-md-4">
                    <img src={img3} className="w-100 img-fluid"/>
                </div>
                </div>
                <div className="col-12 col-md-6 px-3 text-center mb-4 mb-md-5">
                <div className="product-img-wrapper mb-1 mb-md-4">
                    <img src={img1} className="w-100 img-fluid"/>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>

      </Fragment>
    )
  }
}

export default Bathrobe
