import React, { Component } from 'react'
import TopNav from '../components/TopNav/TopNav'
import Towel from '../components/Towel/Towel'
import Footer from '../components/Footer/Footer'

class TowelPage extends Component {
  render() {
    return (
      <div>
        <TopNav title="Towels"/>
        <Towel />
        <Footer />
      </div>
    )
  }
}

export default TowelPage
