import React, { Component } from 'react'
import TopNav from '../components/TopNav/TopNav'
import Story from '../components/Story/Story'
import Footer from '../components/Footer/Footer'

class OurStory extends Component {
  render() {
    return (
      <div>
        <TopNav title="Our Story"/>
        <Story />
        <Footer />
      </div>
    )
  }
}

export default OurStory
