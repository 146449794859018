import React, { Component, Fragment } from 'react'
import poster from '../../securedir/wp-content/themes/towellers/images/landing-hero-header-poster.png'
import video from '../../securedir/wp-content/themes/towellers/videos/landing-hero-header.mp4'

export class VideoMainHome extends Component {
  render() {
    return (
        <Fragment>
        <section id="hero" className="text-white position-relative">
        <video
          className="position-absolute h-100 w-100 object-cover"
          muted
          autoPlay
          loop
          poster={poster}
        >
          <source src={video} type="video/mp4" />
        </video>
        <div className="container text-center">
          <div className="row align-items-center vh-100">
            <div className="col-12 py-5">
              <h1
                className="display-1 text-uppercase text-nowrap"><span
                  className="secondary-color d-block">Superior</span> in
                every strand</h1>
              <div className="h-divider font-serif display-4 mt-4 font-weight-bold"><span>since 1988</span></div>
            </div>
          </div>
        </div>
      </section>
      </Fragment>
    )
  }
}

export default VideoMainHome
