import React, { Component } from 'react'
import TopNav from '../components/TopNav/TopNav'
import Turban from '../components/Turban/Turban'
import Footer from '../components/Footer/Footer'

class TurbanPage extends Component {
  render() {
    return (
      <div>
        <TopNav title="Turban"/>
        <Turban />
        <Footer />
      </div>
    )
  }
}

export default TurbanPage
