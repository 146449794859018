import React, { Component, Fragment } from 'react'
import img1 from '../../securedir/wp-content/themes/towellers/images/ss-1.png'
import img2 from '../../securedir/wp-content/themes/towellers/images/solar.jpg'
import img3 from '../../securedir/wp-content/themes/towellers/images/water.jpg'
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'


class Sustain extends Component {
  render() {
    return (
      <Fragment>
        <section>
  <div className="position-relative container">
    <div className="row align-items-center">
      <svg className="thread-anim thread-sus d-none d-lg-block" fill="none"
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2700 470" height="17%">
        <path
          d="M-105.5 211.5c258.4 177.2 490.5 100 604-28s298-242.3 492-145.5c242.5 121 239.4-29.2 186-8.5-64.5 25 12.6 439 340.5 439 240.5 0 330.5-411.5 632.5-430.5s460.4 87.4 572 413"
          stroke="#bdbc50" stroke-width="2" stroke-miterlimit="10"
          stroke-linejoin="round" className="thread-sus-1"></path>
      </svg>

      <div className="order-1 order-lg-0 col-12 col-lg-5 pr-lg-7">
        <h1 className="display-2 font-weight-bold">Seriously Sustainable</h1>
        <p>Hasham Towel is a firm believer in upholding sustainability standards to
          minimize the impact of textile manufacturing and production, on the
          environment. These standards function to prioritize the health and
          safety of workers, maintain human rights as well as protect the
          environment.</p>
      </div>
      <div className="order-0 order-lg-1 col-12 col-lg-7 mb-4 mb-lg-0">
        <div className="ss-grid a1">
          <img data-sal-delay="0"
            className="img-fluid a2"
            src={img1} alt="SS-1"/>
        </div>
      </div>
    </div>
  </div>
</section>

<section className="mt-7">
  <div className="container">
    <div className="row">
      <div className="col-12">
        <h2 className="m-0">Our Sustainability Impact</h2>
        <p className="mt-3">Annually</p>
      </div>
    </div>
    <div className="row mt-5">
      <div className="col-12 col-lg-4 mb-4 mb-lg-0">
        <p className="display-2 text-warning font-weight-bold">
        <CountUp start={0} end={20}>
          {({ countUpRef, start }) => (
              <VisibilitySensor onChange={start}>
                  <span ref={countUpRef} />
              </VisibilitySensor>
          )}
          </CountUp> tons</p>
        <p className="body-md">CO2 Emissions Reduced</p>
      </div>
      <div className="col-12 col-lg-4 mb-4 mb-lg-0">
        <p className="display-2 text-warning font-weight-bold">
        <CountUp start={0} end={25}>
          {({ countUpRef, start }) => (
              <VisibilitySensor onChange={start}>
                  <span ref={countUpRef} />
              </VisibilitySensor>
          )}
          </CountUp> tons</p>
        <p className="body-md">Coal Saved</p>
      </div>
      <div className="col-12 col-lg-4 mb-4 mb-lg-0">
        <p className="display-2 text-warning font-weight-bold">
        <CountUp start={0} end={150}>
            {({ countUpRef, start }) => (
                <VisibilitySensor onChange={start}>
                    <span ref={countUpRef} />
                </VisibilitySensor>
            )}
            </CountUp>
        </p>
        <p className="body-md">Trees Planted</p>
      </div>
    </div>
  </div>
</section>

<section className="mt-7">
  <div className="container">
    <div className="row">
      <div className="col-12">
        <h2>Conscious of the Planet</h2>
      </div>
    </div>
    <div className="row mt-3">
      <div className="col-12 ">
        <div className="col-md-12">
          <div data-sal-delay="50"
            className="p-5 bg-lightee rounded mb-4">
            <svg width="72" fill="none" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 54 54">
              <path
                d="M10.3 14.6c0 .4-.3.7-.7.7h-8a.7.7 0 0 1 0-1.4h8c.4 0 .7.3.7.7Zm-4 11.8v-7.8a.7.7 0 0 0-1.4 0v7.8a.7.7 0 0 0 1.4 0ZM17 18.6v-8a.7.7 0 0 0-1.4 0v8a.7.7 0 0 0 1.4 0ZM9.6 30.8h-8a.7.7 0 1 0 0 1.4h8a.7.7 0 1 0 0-1.4Zm7.4 4.6v-7.8a.7.7 0 1 0-1.4 0v7.8a.7.7 0 1 0 1.4 0Zm3.3-13.6h-8a.7.7 0 0 0 0 1.4h8a.7.7 0 0 0 0-1.4Zm-4 22c-.4 0-.7.2-.7.6v8a.7.7 0 1 0 1.4 0v-8c0-.4-.3-.7-.7-.7Zm28.1-28.5h8a.7.7 0 1 0 0-1.4h-8a.7.7 0 1 0 0 1.4Zm4-5c.4 0 .7-.3.7-.7v-8a.7.7 0 1 0-1.4 0v8c0 .4.3.7.7.7Zm-.7 8.3v7.8a.7.7 0 1 0 1.4 0v-7.8a.7.7 0 1 0-1.4 0ZM27 10.3c.4 0 .7-.3.7-.7v-8a.7.7 0 1 0-1.4 0v8c0 .4.3.7.7.7ZM9.6 47.7h-8a.7.7 0 1 0 0 1.4h8a.7.7 0 1 0 0-1.4Zm-3.3-4.4v-7.9a.7.7 0 1 0-1.4 0v8a.7.7 0 1 0 1.4 0Zm-.7-33c.4 0 .7-.3.7-.7v-8a.7.7 0 0 0-1.4 0v8c0 .4.3.7.7.7Zm14.7 28.4h-8a.7.7 0 1 0 0 1.4h8a.7.7 0 1 0 0-1.4ZM41.5 4.9h-7.9a.7.7 0 1 0 0 1.4h8a.7.7 0 1 0 0-1.4Zm-3.2 13.7v-8a.7.7 0 1 0-1.4 0v8a.7.7 0 1 0 1.4 0Zm3.2 3.2h-7.9a.7.7 0 1 0 0 1.4h8a.7.7 0 1 0 0-1.4Zm10.7 25.9h-7.9a.7.7 0 1 0 0 1.4h8a.7.7 0 1 0 0-1.4Zm0-16.9h-7.9a.7.7 0 1 0 0 1.4h8a.7.7 0 1 0 0-1.4Zm-32-25.9h-8a.7.7 0 0 0 0 1.4h8a.7.7 0 0 0 0-1.4ZM49 43.3v-7.9a.7.7 0 1 0-1.4 0v8a.7.7 0 1 0 1.4 0Zm-10.7-7.9v-7.8a.7.7 0 1 0-1.4 0v7.8a.7.7 0 1 0 1.4 0Zm-7.5-4.6h-7.8a.7.7 0 1 0 0 1.4h7.8a.7.7 0 1 0 0-1.4Zm-3.2 12.5v-7.9a.7.7 0 1 0-1.4 0v8a.7.7 0 1 0 1.4 0Zm14-4.6h-8a.7.7 0 1 0 0 1.4h8a.7.7 0 1 0 0-1.4Zm-10.8 9h-7.8a.7.7 0 1 0 0 1.4h7.8a.7.7 0 1 0 0-1.4Zm-3.2-21.3v-7.8a.7.7 0 1 0-1.4 0v7.8a.7.7 0 0 0 1.4 0Zm10 17.3c-.4 0-.7.3-.7.7v8a.7.7 0 1 0 1.4 0v-8c0-.4-.3-.7-.7-.7ZM30.9 14h-7.8a.7.7 0 0 0 0 1.4h7.8a.7.7 0 1 0 0-1.4Z"
                fill="#bdbc50" />
            </svg>
            <h5 className="font-sans-serif font-weight-bold my-3">Responsibly
              Sourced Fabric</h5>
            <p>As a member of the Better Cotton Initiative (BCI), Hasham Towel uses eco-friendly fabric, obtained from sustainably grown fiber crops or recycled materials. The company prides itself on providing top-quality fabric that is responsibly sourced. 
</p>
          </div>
          <div data-sal-delay="100"
            className="p-5 bg-lightee rounded mb-4">
            <svg width="72" height="72" viewBox="0 0 72 72" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M31.2352 60.4488H13.9999C13.8359 60.4475 13.675 60.4038 13.5328 60.3221L13.5203 60.3149C12.3357 59.6328 6.7677 50.6174 5.6637 48.816C5.31267 48.1911 5.12933 47.486 5.13151 46.7693C5.1337 46.0526 5.32134 45.3486 5.67618 44.7259L10.6471 36.0734L5.1429 32.9093C5.0601 32.8616 4.9934 32.7903 4.95135 32.7045C4.90929 32.6188 4.8938 32.5224 4.90684 32.4277C4.91988 32.3331 4.96087 32.2445 5.02456 32.1733C5.08824 32.1021 5.17173 32.0515 5.26434 32.028L22.1632 27.7339C22.2842 27.7033 22.4124 27.721 22.5205 27.7833C22.6287 27.8455 22.7083 27.9475 22.7426 28.0675L27.5248 44.8267C27.551 44.9186 27.5492 45.0161 27.5196 45.1069C27.49 45.1977 27.434 45.2776 27.3588 45.3364C27.2835 45.3952 27.1924 45.4303 27.0972 45.437C27.0019 45.4438 26.9068 45.422 26.824 45.3744L21.9117 42.5501C21.0803 44.1264 20.1837 45.8645 19.3 47.6112L31.1839 47.6381C31.3112 47.6381 31.4333 47.6886 31.5233 47.7787C31.6133 47.8687 31.6639 47.9908 31.6639 48.1181L31.7167 59.9688C31.7167 60.0961 31.6661 60.2182 31.5761 60.3082C31.486 60.3982 31.364 60.4488 31.2367 60.4488H31.2352ZM14.02 59.4965C14.0287 59.5018 14.0368 59.508 14.0445 59.5138L30.7533 59.4898L30.7053 48.5971L18.8186 48.5702C16.2506 53.6947 13.9499 58.645 14.02 59.4965ZM6.6477 32.6669L11.5437 35.4806C11.6541 35.544 11.7347 35.6487 11.7679 35.7716C11.8011 35.8944 11.7842 36.0255 11.7208 36.1358L6.50994 45.205C6.23823 45.6805 6.09392 46.2182 6.09098 46.7659C6.08804 47.3136 6.22656 47.8527 6.49314 48.3312C9.01122 52.439 11.8567 56.821 13.2472 58.6392C14.273 55.0646 19.5218 45.0106 21.2954 41.6616C21.3255 41.6046 21.3668 41.5542 21.4168 41.5134C21.4668 41.4727 21.5245 41.4425 21.5865 41.4246C21.6485 41.4066 21.7134 41.4014 21.7774 41.4091C21.8415 41.4169 21.9033 41.4374 21.9592 41.4696L26.2792 43.9522L21.9496 28.7789L6.6477 32.6669Z"
                fill="#bdbc50" />
              <path
                d="M46.9348 67.2C46.8702 67.1999 46.8063 67.1868 46.747 67.1615C46.6876 67.1362 46.6339 67.0991 46.5892 67.0526L34.5023 54.4862C34.4157 54.3962 34.3675 54.2759 34.3682 54.151C34.3688 54.026 34.4181 53.9062 34.5057 53.8171L46.7097 41.376C46.7765 41.3078 46.8623 41.2611 46.9559 41.2418C47.0494 41.2226 47.1466 41.2318 47.235 41.2681C47.3233 41.3045 47.3988 41.3663 47.4518 41.4458C47.5048 41.5253 47.5328 41.6188 47.5324 41.7144L47.5055 47.3784C49.2863 47.3217 51.2409 47.243 53.1959 47.1465L47.3447 36.8049C47.2822 36.6946 47.2659 36.564 47.2994 36.4417C47.3328 36.3194 47.4133 36.2153 47.5233 36.1521L57.7986 30.2481C57.8534 30.2167 57.9139 30.1964 57.9766 30.1883C58.0393 30.1803 58.1029 30.1847 58.1639 30.2014C58.2248 30.218 58.2819 30.2465 58.3318 30.2853C58.3818 30.324 58.4235 30.3722 58.4548 30.4272L66.9757 45.408C67.0558 45.5514 67.0973 45.7132 67.0962 45.8774V45.8904C67.09 47.2589 62.0044 56.5536 60.9849 58.403C60.6169 59.0142 60.0976 59.5204 59.4771 59.8726C58.8567 60.2247 58.1559 60.4111 57.4425 60.4137H57.4233L47.4445 60.3724L47.4148 66.7209C47.4143 66.8164 47.3853 66.9095 47.3316 66.9884C47.2779 67.0673 47.2019 67.1285 47.1133 67.164C47.0567 67.1874 46.9961 67.1996 46.9348 67.2ZM35.5199 54.1564L46.4639 65.5325L46.4903 59.8876C46.4903 59.7603 46.5409 59.6383 46.6309 59.5482C46.7209 59.4582 46.843 59.4076 46.9703 59.4076L57.4285 59.4513H57.4434C57.9886 59.45 58.5243 59.3086 58.9991 59.0405C59.4739 58.7724 59.8717 58.3868 60.1545 57.9206C62.4801 53.7014 64.8829 49.0617 65.7753 46.9545C62.1599 47.8334 50.8257 48.24 47.0399 48.3547C46.9754 48.3572 46.911 48.3464 46.8509 48.3231C46.7907 48.2997 46.7359 48.2642 46.69 48.2188C46.644 48.1738 46.6075 48.1199 46.5828 48.0604C46.5581 48.001 46.5455 47.9372 46.546 47.8728L46.5695 42.8918L35.5199 54.1564ZM48.4175 36.7473L54.2706 47.0932C59.9946 46.7913 65.4349 46.344 66.1401 45.862C66.1401 45.8524 66.1401 45.8424 66.1429 45.8328L57.8596 31.32L48.4175 36.7473Z"
                fill="#bdbc50" />
              <path
                d="M51.2542 29.5791C51.222 29.5788 51.1898 29.5755 51.1582 29.569L34.0947 26.0112C34.0012 25.9917 33.9157 25.9448 33.849 25.8764C33.7824 25.808 33.7376 25.7213 33.7205 25.6273C33.7034 25.5334 33.7147 25.4365 33.753 25.349C33.7913 25.2615 33.8548 25.1874 33.9354 25.1362L38.72 22.1002C37.712 20.6314 36.5898 19.0282 35.4531 17.4351L29.9043 27.9432C29.8452 28.0553 29.7441 28.1395 29.6232 28.1773C29.5022 28.2152 29.3712 28.2037 29.2587 28.1453L18.7419 22.6824C18.6859 22.6533 18.6361 22.6133 18.5955 22.5649C18.5549 22.5165 18.5243 22.4605 18.5054 22.4002C18.4865 22.3399 18.4797 22.2765 18.4853 22.2135C18.491 22.1506 18.509 22.0894 18.5384 22.0335L26.5515 6.77377C26.6283 6.6289 26.7417 6.50662 26.8803 6.41905L26.8909 6.41137C28.0467 5.67841 38.6158 4.94257 40.723 4.80241C41.4398 4.78012 42.1501 4.94476 42.784 5.28013C43.4179 5.61551 43.9536 6.11008 44.3384 6.71521L49.6894 15.1378L55.0515 11.736C55.1322 11.6849 55.2263 11.659 55.3218 11.6616C55.4172 11.6642 55.5098 11.6953 55.5875 11.7508C55.6652 11.8063 55.7246 11.8838 55.7581 11.9732C55.7915 12.0627 55.7976 12.1601 55.7754 12.253L51.7203 29.2104C51.6953 29.3153 51.6357 29.4087 51.5512 29.4755C51.4666 29.5424 51.362 29.5789 51.2542 29.5791ZM35.4483 25.3128L50.8952 28.5341L54.5667 13.1808L49.8003 16.2048C49.6929 16.273 49.5627 16.2958 49.4385 16.268C49.3143 16.2403 49.2062 16.1644 49.1379 16.057L43.5296 7.22977C43.2346 6.76825 42.8257 6.39054 42.3423 6.13309C41.8588 5.87565 41.3172 5.74715 40.7696 5.76001C35.9619 6.08017 30.7597 6.56161 28.5037 6.94801C31.1917 9.51793 37.6525 18.8391 39.7928 21.9667C39.8292 22.0199 39.8545 22.0798 39.8672 22.143C39.88 22.2061 39.8799 22.2712 39.8669 22.3343C39.854 22.3974 39.8285 22.4573 39.7919 22.5103C39.7553 22.5634 39.7085 22.6085 39.6541 22.6431L35.4483 25.3128ZM19.6117 22.0522L29.2803 27.072L34.8286 16.56C31.485 11.904 28.1715 7.56673 27.3853 7.23265C27.3766 7.23745 27.3675 7.24177 27.3584 7.24609L19.6117 22.0522Z"
                fill="#bdbc50" />
              <path
                d="M31.2352 60.4488H13.9999C13.8359 60.4475 13.675 60.4038 13.5328 60.3221L13.5203 60.3149C12.3357 59.6328 6.7677 50.6174 5.6637 48.816C5.31267 48.1911 5.12933 47.486 5.13151 46.7693C5.1337 46.0526 5.32134 45.3486 5.67618 44.7259L10.6471 36.0734L5.1429 32.9093C5.0601 32.8616 4.9934 32.7903 4.95135 32.7045C4.90929 32.6188 4.8938 32.5224 4.90684 32.4277C4.91988 32.3331 4.96087 32.2445 5.02456 32.1733C5.08824 32.1021 5.17173 32.0515 5.26434 32.028L22.1632 27.7339C22.2842 27.7033 22.4124 27.721 22.5205 27.7833C22.6287 27.8455 22.7083 27.9475 22.7426 28.0675L27.5248 44.8267C27.551 44.9186 27.5492 45.0161 27.5196 45.1069C27.49 45.1977 27.434 45.2776 27.3588 45.3364C27.2835 45.3952 27.1924 45.4303 27.0972 45.437C27.0019 45.4438 26.9068 45.422 26.824 45.3744L21.9117 42.5501C21.0803 44.1264 20.1837 45.8645 19.3 47.6112L31.1839 47.6381C31.3112 47.6381 31.4333 47.6886 31.5233 47.7787C31.6133 47.8687 31.6639 47.9908 31.6639 48.1181L31.7167 59.9688C31.7167 60.0961 31.6661 60.2182 31.5761 60.3082C31.486 60.3982 31.364 60.4488 31.2367 60.4488H31.2352ZM14.02 59.4965C14.0287 59.5018 14.0368 59.508 14.0445 59.5138L30.7533 59.4898L30.7053 48.5971L18.8186 48.5702C16.2506 53.6947 13.9499 58.645 14.02 59.4965ZM6.6477 32.6669L11.5437 35.4806C11.6541 35.544 11.7347 35.6487 11.7679 35.7716C11.8011 35.8944 11.7842 36.0255 11.7208 36.1358L6.50994 45.205C6.23823 45.6805 6.09392 46.2182 6.09098 46.7659C6.08804 47.3136 6.22656 47.8527 6.49314 48.3312C9.01122 52.439 11.8567 56.821 13.2472 58.6392C14.273 55.0646 19.5218 45.0106 21.2954 41.6616C21.3255 41.6046 21.3668 41.5542 21.4168 41.5134C21.4668 41.4727 21.5245 41.4425 21.5865 41.4246C21.6485 41.4066 21.7134 41.4014 21.7774 41.4091C21.8415 41.4169 21.9033 41.4374 21.9592 41.4696L26.2792 43.9522L21.9496 28.7789L6.6477 32.6669Z"
                fill="#bdbc50" />
              <path
                d="M46.9348 67.2C46.8702 67.1999 46.8063 67.1868 46.747 67.1615C46.6876 67.1362 46.6339 67.0991 46.5892 67.0526L34.5023 54.4862C34.4157 54.3962 34.3675 54.2759 34.3682 54.151C34.3688 54.026 34.4181 53.9062 34.5057 53.8171L46.7097 41.376C46.7765 41.3078 46.8623 41.2611 46.9559 41.2418C47.0494 41.2226 47.1466 41.2318 47.235 41.2681C47.3233 41.3045 47.3988 41.3663 47.4518 41.4458C47.5048 41.5253 47.5328 41.6188 47.5324 41.7144L47.5055 47.3784C49.2863 47.3217 51.2409 47.243 53.1959 47.1465L47.3447 36.8049C47.2822 36.6946 47.2659 36.564 47.2994 36.4417C47.3328 36.3194 47.4133 36.2153 47.5233 36.1521L57.7986 30.2481C57.8534 30.2167 57.9139 30.1964 57.9766 30.1883C58.0393 30.1803 58.1029 30.1847 58.1639 30.2014C58.2248 30.218 58.2819 30.2465 58.3318 30.2853C58.3818 30.324 58.4235 30.3722 58.4548 30.4272L66.9757 45.408C67.0558 45.5514 67.0973 45.7132 67.0962 45.8774V45.8904C67.09 47.2589 62.0044 56.5536 60.9849 58.403C60.6169 59.0142 60.0976 59.5204 59.4771 59.8726C58.8567 60.2247 58.1559 60.4111 57.4425 60.4137H57.4233L47.4445 60.3724L47.4148 66.7209C47.4143 66.8164 47.3853 66.9095 47.3316 66.9884C47.2779 67.0673 47.2019 67.1285 47.1133 67.164C47.0567 67.1874 46.9961 67.1996 46.9348 67.2ZM35.5199 54.1564L46.4639 65.5325L46.4903 59.8876C46.4903 59.7603 46.5409 59.6383 46.6309 59.5482C46.7209 59.4582 46.843 59.4076 46.9703 59.4076L57.4285 59.4513H57.4434C57.9886 59.45 58.5243 59.3086 58.9991 59.0405C59.4739 58.7724 59.8717 58.3868 60.1545 57.9206C62.4801 53.7014 64.8829 49.0617 65.7753 46.9545C62.1599 47.8334 50.8257 48.24 47.0399 48.3547C46.9754 48.3572 46.911 48.3464 46.8509 48.3231C46.7907 48.2997 46.7359 48.2642 46.69 48.2188C46.644 48.1738 46.6075 48.1199 46.5828 48.0604C46.5581 48.001 46.5455 47.9372 46.546 47.8728L46.5695 42.8918L35.5199 54.1564ZM48.4175 36.7473L54.2706 47.0932C59.9946 46.7913 65.4349 46.344 66.1401 45.862C66.1401 45.8524 66.1401 45.8424 66.1429 45.8328L57.8596 31.32L48.4175 36.7473Z"
                fill="#bdbc50" />
              <path
                d="M51.2542 29.5791C51.222 29.5788 51.1898 29.5755 51.1582 29.569L34.0947 26.0112C34.0012 25.9917 33.9157 25.9448 33.849 25.8764C33.7824 25.808 33.7376 25.7213 33.7205 25.6273C33.7034 25.5334 33.7147 25.4365 33.753 25.349C33.7913 25.2615 33.8548 25.1874 33.9354 25.1362L38.72 22.1002C37.712 20.6314 36.5898 19.0282 35.4531 17.4351L29.9043 27.9432C29.8452 28.0553 29.7441 28.1395 29.6232 28.1773C29.5022 28.2152 29.3712 28.2037 29.2587 28.1453L18.7419 22.6824C18.6859 22.6533 18.6361 22.6133 18.5955 22.5649C18.5549 22.5165 18.5243 22.4605 18.5054 22.4002C18.4865 22.3399 18.4797 22.2765 18.4853 22.2135C18.491 22.1506 18.509 22.0894 18.5384 22.0335L26.5515 6.77377C26.6283 6.6289 26.7417 6.50662 26.8803 6.41905L26.8909 6.41137C28.0467 5.67841 38.6158 4.94257 40.723 4.80241C41.4398 4.78012 42.1501 4.94476 42.784 5.28013C43.4179 5.61551 43.9536 6.11008 44.3384 6.71521L49.6894 15.1378L55.0515 11.736C55.1322 11.6849 55.2263 11.659 55.3218 11.6616C55.4172 11.6642 55.5098 11.6953 55.5875 11.7508C55.6652 11.8063 55.7246 11.8838 55.7581 11.9732C55.7915 12.0627 55.7976 12.1601 55.7754 12.253L51.7203 29.2104C51.6953 29.3153 51.6357 29.4087 51.5512 29.4755C51.4666 29.5424 51.362 29.5789 51.2542 29.5791ZM35.4483 25.3128L50.8952 28.5341L54.5667 13.1808L49.8003 16.2048C49.6929 16.273 49.5627 16.2958 49.4385 16.268C49.3143 16.2403 49.2062 16.1644 49.1379 16.057L43.5296 7.22977C43.2346 6.76825 42.8257 6.39054 42.3423 6.13309C41.8588 5.87565 41.3172 5.74715 40.7696 5.76001C35.9619 6.08017 30.7597 6.56161 28.5037 6.94801C31.1917 9.51793 37.6525 18.8391 39.7928 21.9667C39.8292 22.0199 39.8545 22.0798 39.8672 22.143C39.88 22.2061 39.8799 22.2712 39.8669 22.3343C39.854 22.3974 39.8285 22.4573 39.7919 22.5103C39.7553 22.5634 39.7085 22.6085 39.6541 22.6431L35.4483 25.3128ZM19.6117 22.0522L29.2803 27.072L34.8286 16.56C31.485 11.904 28.1715 7.56673 27.3853 7.23265C27.3766 7.23745 27.3675 7.24177 27.3584 7.24609L19.6117 22.0522Z"
                fill="#bdbc50" />
            </svg>

            <h5 className="font-sans-serif font-weight-bold my-3">Sustainable
              Manufacturing</h5>
            <p>Hasham Towel introduced the ERP System, which enables a paperless
              environment and supports sustainable manufacturing. The company
              reevaluates its practices every year and strives to become more
              responsible in its manufacturing and production.</p>
          </div>
          </div>
          <div className="col-md-12">
          <div data-sal-delay="150"
            className="p-5 bg-lightee rounded mb-4">
            <svg width="72" fill="none" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 72 72">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M63 47.8H9c-.5 0-1-.3-1.5-.8-.3-.3-.5-.7-.4-1.2l1.7-9v-.1l2.4-12.9 1.7-9c.1-1.1.9-1.8 1.8-1.8h42.6c1 0 1.7.7 1.8 1.8l1.7 9 2.4 13 1.7 9c0 .5-.2 1-.4 1.2-.4.5-1 .8-1.6.8ZM9.7 37.5 8.3 46v.4c.2 0 .5.3.8.3h53.8c.3 0 .6-.2.7-.3l.1-.4-1.5-8.5H9.8Zm.3-1.2h51.8l-2.1-11.8H12.2l-2.1 11.8Zm2.3-13h47.2L58 15s-.1-.9-.7-.9H14.7c-.6 0-.7.8-.7 1l-1.6 8.3Z"
                fill="#bdbc50" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M18.6 47.8c-.3 0-.5-.3-.5-.6l4.5-33.6c.1-.4.3-.6.7-.6.3.1.5.3.4.7l-4.5 33.6c0 .3-.2.5-.6.5ZM53.4 47.8c-.4 0-.6-.2-.6-.5l-4.5-33.6c-.1-.4.1-.6.4-.7.4 0 .6.2.7.6L54 47.2c0 .3-.2.5-.5.5v.1ZM36 47.8c-.3 0-.6-.3-.6-.5V13.6c0-.4.3-.6.6-.6s.6.2.6.6v33.7c0 .2-.3.5-.6.5Z"
                fill="#bdbc50" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M44.8 51.9H27.2c-.2 0-.6-.2-.6-.6v-4c0-.4.4-.6.6-.6h17.6c.2 0 .6.2.6.6v4c0 .4-.4.6-.6.6Zm-17-1.1h16.4v-3H27.8v3Z"
                fill="#bdbc50" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M32.3 59c-.2 0-.6-.2-.6-.6v-7c0-.3.4-.6.6-.6.3 0 .5.3.5.5v7.1c0 .4-.2.6-.5.6ZM39.7 59c-.3 0-.5-.2-.5-.6v-7c0-.3.2-.6.5-.6.2 0 .6.3.6.5v7.1c0 .4-.4.6-.6.6Z"
                fill="#bdbc50" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M46.8 59H25.2c-.4 0-.6-.2-.6-.6 0-.3.2-.5.6-.5h21.6c.4 0 .6.2.6.5 0 .4-.2.6-.6.6Z"
                fill="#bdbc50" />
            </svg>
            <h5 className="font-sans-serif font-weight-bold my-3">Solar Panels</h5>
            <p>Hasham Towel has solar panels installed across all three of its
              production sites, generating over 1 Megawatt of energy.</p>
            <ul className="d-none">
              <li>530 KW at T-4</li>
              <li>398 KW at the Dyehouse</li>
              <li>100 KW at the Head Office</li>
            </ul>
          </div>
          <div data-sal-delay="200"
            className="p-5 bg-lightee rounded">
            <svg width="72" fill="none" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 72 72">
              <path
                d="M56.6 12.7H15.4a.4.4 0 0 1-.4-.4V7.4a.4.4 0 0 1 .4-.4h41.2a.4.4 0 0 1 .4.4v4.9a.4.4 0 0 1-.4.4Zm-40.7-.9H56v-4H16v4ZM56.6 65H15.4a.4.4 0 0 1-.4-.4v-4.9a.4.4 0 0 1 .4-.4h41.2a.4.4 0 0 1 .4.4v4.9a.4.4 0 0 1-.4.4Zm-40.7-.9H56v-4H16v4Z"
                fill="#bdbc50" />
              <path
                d="M54.3 60.2a.4.4 0 0 1-.5-.5V12.3a.4.4 0 1 1 1 0v47.4a.4.4 0 0 1-.5.5ZM17.7 60.2a.4.4 0 0 1-.4-.5V12.3a.4.4 0 1 1 .9 0v47.4a.4.4 0 0 1-.5.5ZM36 44.3a5 5 0 0 1-4.9-4.9A52.4 52.4 0 0 1 36 27.7a.5.5 0 0 1 .4.2c.2.4 4.5 9 4.5 11.5a5 5 0 0 1-4.9 5Zm0-15.2c-1.4 2.9-4 8.6-4 10.3a4 4 0 0 0 8 0c0-1.7-2.6-7.4-4-10.3Z"
                fill="#bdbc50" />
              <path
                d="M54.3 22H17.7a.4.4 0 1 1 0-1h36.6a.4.4 0 0 1 0 1ZM54.3 51H17.7a.4.4 0 1 1 0-1h36.6a.4.4 0 0 1 0 1ZM56.6 12.7H15.4a.4.4 0 0 1-.4-.4V7.4a.4.4 0 0 1 .4-.4h41.2a.4.4 0 0 1 .4.4v4.9a.4.4 0 0 1-.4.4Zm-40.7-.9H56v-4H16v4ZM56.6 65H15.4a.4.4 0 0 1-.4-.4v-4.9a.4.4 0 0 1 .4-.4h41.2a.4.4 0 0 1 .4.4v4.9a.4.4 0 0 1-.4.4Zm-40.7-.9H56v-4H16v4Z"
                fill="#bdbc50" />
              <path
                d="M54.3 60.2a.4.4 0 0 1-.5-.5V12.3a.4.4 0 1 1 1 0v47.4a.4.4 0 0 1-.5.5ZM17.7 60.2a.4.4 0 0 1-.4-.5V12.3a.4.4 0 1 1 .9 0v47.4a.4.4 0 0 1-.5.5ZM36 44.3a5 5 0 0 1-4.9-4.9A52.4 52.4 0 0 1 36 27.7a.5.5 0 0 1 .4.2c.2.4 4.5 9 4.5 11.5a5 5 0 0 1-4.9 5Zm0-15.2c-1.4 2.9-4 8.6-4 10.3a4 4 0 0 0 8 0c0-1.7-2.6-7.4-4-10.3Z"
                fill="#bdbc50" />
              <path
                d="M54.3 22H17.7a.4.4 0 1 1 0-1h36.6a.4.4 0 0 1 0 1ZM54.3 51H17.7a.4.4 0 1 1 0-1h36.6a.4.4 0 0 1 0 1Z"
                fill="#bdbc50" />
            </svg>
            <h5 className="font-sans-serif font-weight-bold my-3">Safe Chemicals
            </h5>
            <p>The company uses Oeko-Tex-certified, non-hazardous chemicals in all its manufacturing, production, and dyeing processes to make sure it is safe for humans and the environment.
</p>
          </div>
          </div>
        
      </div>
    </div>
  </div>
</section>

<section className="mt-7 mb-lg-7 position-relative">
  <div className="container">
    <div className="row">
      <div className="col-12">
        <h2>Going the Extra Mile</h2>
      </div>
    </div>
    <div className="row mt-4">
      <div className="col-12 col-lg-6 pr-lg-0 mb-5 mb-lg-0">
        <p className="body-md text-success-darker mb-4">
          <svg height="40" fill="none" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40">
            <g clip-path="url(#a)">
              <path
                d="M22.6 0c-.2 0-.4.1-.5.3l-15.3 22c-.3.4 0 1 .5 1H18l-1.2 16c0 .7.8 1 1.2.4l15.3-22c.3-.4 0-1-.5-1H21.3c-.9 0-.9 1.3 0 1.3h10L18.3 37l1.1-14.3c0-.4-.2-.7-.6-.7h-10L21.7 3l-1 11.6c0 1 1.4 1 1.4.1l1.1-14c0-.4-.3-.7-.7-.7Z"
                fill="#7AA096" />
            </g>
            <defs>
              <clipPath id="a">
                <path fill="#fff" d="M0 0H40V40H0z" />
              </clipPath>
            </defs>
          </svg>
          <strong>Energy Efficiency</strong>
        </p>
        <img src={img2} alt="" className='img-fluid rounded' />
      </div>
      <div className="col-12 col-lg-6 pr-lg-0 mb-5 mb-lg-0">
        <p className="body-md text-success-darker mb-4">
          <svg height="40" fill="none" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40">
            <g clip-path="url(#a)" fill="#7AA096">
              <path
                d="m26.1 12-2.7-5.4a.6.6 0 1 0-1 .5l2.7 5.4a36.2 36.2 0 0 1 5.6 15.6A10.6 10.6 0 0 1 20 38.8c-2.9 0-5.5-1.1-7.6-3.2-2-2-3.1-4.7-3-7.6 0-5 2.6-10 5.5-15.5C17 8.8 19 5 20 1.2l1.2 3.4a.6.6 0 0 0 1-.5 33 33 0 0 1-1-3.3C21 .3 20.4 0 20 0s-1 .3-1.1.8c-1.1 3.8-3.1 7.5-5 11.2A37.9 37.9 0 0 0 8 28c0 3.2 1.2 6.2 3.5 8.5a11.8 11.8 0 0 0 20.3-8.3c0-5.3-3-10.9-5.8-16.2Z" />
              <path
                d="M28 29.5c-.3-.1-.6 0-.6.4-.7 2.7-2.9 5-5.7 5.6a.6.6 0 1 0 .3 1.1 8.8 8.8 0 0 0 6.5-6.4c0-.4-.1-.7-.4-.7Z" />
            </g>
            <defs>
              <clipPath id="a">
                <path fill="#fff" d="M0 0H40V40H0z" />
              </clipPath>
            </defs>
          </svg>
          <strong>Water Conservation</strong>
        </p>
        <img src={img3} alt="" className='img-fluid rounded' />
      </div>
    </div>
  </div>

  <svg className="thread-anim thread-sus2 d-none d-lg-block" fill="none"
    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 202 334" width="202"
    height="334">
    <path
      d="M280 1C196 125.5 33.6 190.8 5 111c-28.5-79.8 102.5-128.5 134.7 30.6C165.4 269 239.7 322.3 273.6 333"
      stroke="#bdbc50" stroke-width="2" stroke-miterlimit="10"
      stroke-linejoin="round" className="thread-sus2-1"></path>
  </svg>

</section>
      </Fragment>
    )
  }
}

export default Sustain
