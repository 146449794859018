import React, { Component, Fragment } from 'react'
import img1 from '../../securedir/wp-content/themes/towellers/images/baby/sauna.jpeg'

class Sauna extends Component {
  render() {
    return (
        <Fragment>
        <section>
        <div className="container py-5 py-md-6">
            <div className="row">
            <div className="col-12">
                <h1 className="display-2"> Sauna Kits</h1>
            </div>
            </div>
            <div className="row mt-3">
            <div className="col-12 col-md-3 mb-4 mb-md-0">
                <h3>Sauna Kits made <br className="d-none d-md-block" />with love
                and care</h3>
            </div>
            <div className="col-auto border-right p-0"></div>
            <div className="col-md pl-md-5">
                <p>As a leading manufacturer of sauna kits, we specialize in creating high-quality, 
                    easy-to-install kits that bring the luxury of a sauna experience into the comfort 
                    of your home. Our sauna kits are crafted using premium-grade materials, ensuring 
                    optimal heat retention, durability, and safety. Designed with user convenience in 
                    mind, our kits are simple to assemble and come with all the necessary components 
                    for a seamless setup. </p>
            </div>
            </div>
        </div>
        </section>

        <section className="pb-5 py-md-7 bg-white">
        <div className="product-row">
            <div className="container">
            <div className="row">
                <div className="col-12 col-md-6 px-3 text-center mb-4 mb-md-5">
                <div className="product-img-wrapper mb-1 mb-md-4">
                    <img src={img1} className="w-100 img-fluid"/>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>

      </Fragment>

    )
  }
}

export default Sauna
