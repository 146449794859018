import React, { Component, Fragment } from 'react'
import img1 from '../../securedir/wp-content/themes/towellers/images/hib-1.png'
import img2 from '../../securedir/wp-content/themes/towellers/images/hib-3.png'
import img3 from '../../securedir/wp-content/themes/towellers/images/hib-2.png'
import img4 from '../../securedir/wp-content/themes/towellers/images/hib-4.jpg'
import img5 from '../../securedir/wp-content/themes/towellers/images/how-it-began-m.png'
import img6 from '../../securedir/wp-content/themes/towellers/images/our-impact.png'
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { Zoom } from "react-reveal";


class Capacity extends Component {
  render() {
    return (
      <Fragment>
        <section id="driving-innovation-forward" className="position-relative bg-secondary pt-3">
        <svg className="thread-anim thread-3 d-none d-lg-block" fill="none"
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2678 850" width="100%"
        height="850">
        <path
            d="M2703.3 158.4c-293.2-200.7-705.6-209.3-1006.9-21-187.5 117.1-325.7 296.8-486.8 448.3C1048.6 737.3 842 868 622 846.3c-112.3-11-216.5-60.7-324.6-92.7-108.1-32-231.7-44.6-328 14"
            stroke="#fff" stroke-width="3" className="thread-3-1"></path>
        </svg>

        <svg className="thread-anim m-thread-2 d-block d-lg-none" fill="none"
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 390 81" width="100%"
        height="81">
        <path d="M-38.5 80c57.7-41.3 230.2-111.6 459-62" stroke-width="2"
            stroke="#fff" stroke-miterlimit="10" stroke-linejoin="round"
            className="m-thread-2-1"></path>
        </svg>

        <div className="container">
        <div className="row align-items-center">
            <div className="col-1"></div>
            <div className="order-1 order-lg-0 col-12 col-lg-4">
            <h2>Driving innovation forward</h2>
            <p>Hasham Towel prides itself on creating superior products through the
                latest technology and innovation. The drive to improve every day is
                what motivates the company’s growth.</p>
            <p>
                <Link to="/our-facilities" className="btn btn-link">Our Production Suite <span
                    className="material-icons">
                    east
                </span></Link>
            </p>
            </div>
            <div className="order-0 order-lg-1 col-12 col-lg-6 mb-4 mb-lg-0 p-4 p-lg-5">
            <div className="col-12 col-lg-5 px-lg-4 mb-4 mb-lg-0">
            <div className="d-none d-lg-flex gap-div">
                <div className="div d-flex flex-column mt-3">
                <Zoom>
                <img className="rounded ml-2" width="200"
                    src={img1} alt="HIB 1"/>
                <img src={img2} className="pl-5 mt-3"
                    alt="HIB 1"/>
                    </Zoom>
                </div>  
                <div className="mt-n5">
                <Zoom>
                <img src={img3} alt="HIB 1"/>
                <img className="rounded mt-4" width="200" 
                 src={img4} alt="HIB 1"/>
                </Zoom>
                </div>
            </div>
            <Zoom>
            <img className="pr-9 pr-lg-0 img-fluid w-100 d-lg-none" src={img5}
                alt="How it began"/>
            </Zoom>
            </div>
            </div>
        </div>
        </div>

        <div className="container position-relative z-1">

        <svg className="thread-anim m-thread-3 d-block d-lg-none" fill="none"
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 390 59" width="100%"
            height="59">
            <path d="M423.9 23.5C359.6 53.6 177.2 91.3-38.8.8" stroke="#fff"
            stroke-miterlimit="10" stroke-linejoin="round" stroke-width="2"
            className="m-thread-3-1"></path>
        </svg>

        <div className="row align-items-center">
            <div className="col-1"></div>
            <div className="col-12 col-lg-5 mb-4 mb-lg-0 p-4 p-lg-5">
            <div className="img-border-wrapper tilt-right">
            <Zoom>
                <picture>
                <source media="(min-width:768px)"
                    srcset={img6}/>
                <img className="img-fluid w-100"
                    src={img6} alt="Our impact"/>
                </picture>
                </Zoom>
            </div>
            </div>
            <div className="col-1"></div>
            <div className="col-12 col-lg-5">
            <h2>Our Capacity</h2>
            <div className="row text-center">
                <div className="col-5">
                <p className="display-2 font-weight-bold">
                <CountUp start={0} end={300}>
                {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start}>
                        <span ref={countUpRef} />
                    </VisibilitySensor>
                )}
                </CountUp>k+
                </p>
                <p className="font-weight-bold">kg. of Towels Produced per month</p>
                </div>
                <div className="col-4">
                <p className="display-2 font-weight-bold">
                <CountUp start={0} end={90}>
                {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start}>
                        <span ref={countUpRef} />
                    </VisibilitySensor>
                )}
                </CountUp>k+
                </p>
                <p className="font-weight-bold">Pcs Bathrobes Produced per month</p>
                </div>
                <div className="col-3">
                <p className="display-2 font-weight-bold">
                <CountUp start={0} end={36}>
                {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start}>
                        <span ref={countUpRef} />
                    </VisibilitySensor>
                )}
                </CountUp>
                </p>
                <p className="font-weight-bold">Years of Service</p>
                </div>
            </div>
            </div>
        </div>
        </div>
        </section>
      </Fragment>
    )
  }
}

export default Capacity
