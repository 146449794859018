import React, { Component } from 'react'
import TopNav from '../components/TopNav/TopNav'
import AboutUs from '../components/AboutUs/AboutUs'
import Footer from '../components/Footer/Footer'

class AboutUsPage extends Component {
  render() {
    return (
      <div>
        <TopNav title="About Us"/>
        <AboutUs />
        <Footer />
      </div>
    )
  }
}

export default AboutUsPage
