import React, { Component, Fragment } from 'react'
import { Container, Nav, NavDropdown, Navbar } from 'react-bootstrap'
import '../../securedir/bootstrap.css'
import '../../securedir/wp-content/themes/towellers/dist/appef10.css'
import '../../securedir/wp-content/themes/towellers/dist/salef10.css'
import foot from '../../securedir/wp-content/themes/towellers/images/new-logo.png'
import { NavLink } from 'react-router-dom/cjs/react-router-dom'

export class TopNav extends Component {
  constructor(props) {
    super();
    this.state={
        pageTitle:props.title
    }
}
      
  render() {
    return (
      <Fragment>
        <Container>
        <title>{this.state.pageTitle}</title>
      <Navbar expand="lg" className="p-0">
        <Container>
        <Navbar.Brand className="font-weight-bold p-0">
          <NavLink to="/">
            <img
              alt="Logo"
              src={foot}
              height={110}
            /></NavLink>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="navbarToggler"
            className="d-inline-flex d-lg-none border-0 outline-0 outline-focus-0 p-0"
            aria-label="Toggle navigation"
            title="Menu"><i className="navbar-toggler-bars"></i>
          </Navbar.Toggle>
          <Navbar.Collapse id="navbarToggler">
          <Nav className="ml-auto">
              <Nav.Link><NavLink exact activeStyle={{color:'#bebd50'}} to="/about-us">About Us</NavLink></Nav.Link>
              <NavDropdown title="Products" id="basic-nav-dropdown">
                <NavDropdown.Item><NavLink exact activeStyle={{color:'#bebd50'}} to="/towels">Towels</NavLink></NavDropdown.Item>
                <NavDropdown.Item><NavLink exact activeStyle={{color:'#bebd50'}} to="/bathrobe">Bathrobes</NavLink></NavDropdown.Item>
                <NavDropdown.Item><NavLink exact activeStyle={{color:'#bebd50'}} to="/bathmate">Bath Mates</NavLink></NavDropdown.Item>
                  <NavDropdown title="Terry MadeUps" id="basic-nav-dropdown">

                  <NavDropdown.Item><NavLink exact activeStyle={{color:'#bebd50'}} to="/terry-blanket">Terry Blanket</NavLink></NavDropdown.Item>
                  <NavDropdown.Item><NavLink exact activeStyle={{color:'#bebd50'}} to="/turban">Turban/Hair Holder</NavLink></NavDropdown.Item>
                  <NavDropdown.Item><NavLink exact activeStyle={{color:'#bebd50'}} to="/sauna-kits">Sauna Kits</NavLink></NavDropdown.Item>
                  <NavDropdown.Item><NavLink exact activeStyle={{color:'#bebd50'}} to="/slippers">Slippers</NavLink></NavDropdown.Item>
                  </NavDropdown>
              </NavDropdown>
              <Nav.Link><NavLink exact activeStyle={{color:'#bebd50'}} to="/our-story">Our Story</NavLink></Nav.Link>
              <Nav.Link><NavLink exact activeStyle={{color:'#bebd50'}} to="/sustainability">Sustainability</NavLink></Nav.Link>
              <Nav.Link><NavLink exact activeStyle={{color:'#bebd50'}} to="/our-facilities">Our Facilities</NavLink></Nav.Link>
              <Nav.Link><NavLink exact activeStyle={{color:'#bebd50'}} to="/contact-us">Contact Us</NavLink></Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      </Container>
      </Fragment>
    )
  }
}

export default TopNav
