import React, { Component, Fragment } from 'react'
import blank from '../../securedir/wp-content/themes/towellers/images/blank.png'

class AboutUs extends Component {
  render() {
    return (
      <Fragment>
        <div className="position-relative d-none d-lg-block">
        <svg className="thread-anim thread-investors" fill="none"
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 238" width="352"
            height="238">
            <path d="M378 45.5C244.8 164-14 213 1.7 80 17.5-53 313.7-29.3 378 237"
            stroke="#bdbc50" stroke-width="2" stroke-miterlimit="10"
            stroke-linejoin="round" className="thread-investors-1"></path>
        </svg>
        </div>

        <section className="my-5 my-md-7">
        <div className="container">
            <div className="row">
            <div className="col-12">
                <h1 className="display-2 font-weight-bold text-primary">About Us</h1>
                <div className="row">
                <div className="col-12 col-md-8">
                    <p>Since the establishment of Hasham Towels in 1988, the company has become 
                      well known for its continuous efforts to develop latest designs in towels and 
                      bathrobes. More than 35 years, the company has achieved a significant level of etxport 
                      sales and has become well known globally for supplying quality products. The 
                      company has grown considerably since its early days and has established its mark 
                      in bath accessories. An inherent desire for technical excellence leads Hasham to the 
                      achievement of ISO 9002 Certification. We successfully participate in a number of international 
                      trade fairs throughout the year including the hemitextil, Frankfurt, displaying the best towels 
                      and bathrobes of all types and sizes.</p>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>

        <section className="mb-7">
        <div className="container">
            <div className="row">
            <div className="col-12 col-md-6 d-flex mb-4 mb-md-0">
                <div data-sal-delay="0"
                className="card card-body border-0 bg-secondary-lighter p-4 p-md-5">
                <h3 className="mb-4">Vision</h3>
                <p className="m-0">Be a global leader in the production of premium textile products.	</p>
                </div>
            </div>
            <div className="col-12 col-md-6 d-flex">
                <div data-sal-delay="50"
                className="card card-body border-0 bg-secondary-lighter p-4 p-md-5">
                <h3 className="mb-4">Mission</h3>
                <p className="m-0">Drive growth through sustainable and innovative manufacturing, where we put our customers and stakeholders first.</p>
                </div>
            </div>
            </div>
        </div>
        </section>
      </Fragment>
    )
  }
}

export default AboutUs
